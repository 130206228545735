import ArrowRightIcon from '@wearemojo/icons/Outline/Arrows/ArrowRightIcon';
import React, { ElementRef, useEffect, useRef } from 'react';
import { ImageBackground, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import { pattern6 as backgroundImage } from './assets';
import BottomSheet from './BottomSheet';
import Button from './Button';
import ChallengeProgressRing from './ChallengeProgressRing';
import Chip from './Chip';
import ContainedAction from './ContainedAction';
import CupStarGradientIcon from './icons/CupStarGradientIcon';
import Image from './Image';
import Text from './Text';
import { ContentVariables, InlineContent } from './utils/types';

type Props = {
	visible?: boolean;
	onClose?: () => void;
	title: string;
	subtitle: InlineContent;
	supportLabel: InlineContent;
	chipContent?: InlineContent;
	imageSource?: string;
	contentVariables?: ContentVariables;
	ctaTitle: string;
	onCtaPress: () => void;
	progress: number;
	total: number;
};

const ChallengeProgressBottomSheet = ({
	visible = false,
	onClose,
	title,
	subtitle,
	supportLabel,
	chipContent,
	contentVariables,
	imageSource,
	onCtaPress,
	ctaTitle,
	progress,
	total,
}: Props) => {
	const {
		styles,
		theme: { iconSize, spacing },
	} = useStyles(stylesheet);
	const safeAreaInsets = useSafeAreaInsets();
	const bottomSheetRef = useRef<ElementRef<typeof BottomSheet>>(null);

	useEffect(() => {
		if (visible) bottomSheetRef.current?.present();
		if (!visible) bottomSheetRef.current?.dismiss();
	}, [visible]);

	const handleOnClose = () => {
		bottomSheetRef.current?.toggleOverlay();
		onClose?.();
	};

	const hasCompleted = progress === total && chipContent;

	return (
		<BottomSheet ref={bottomSheetRef} onDismiss={onClose}>
			<View style={styles.root}>
				<ImageBackground
					source={backgroundImage}
					resizeMode="cover"
					style={[
						styles.container,
						{ paddingBottom: Math.max(safeAreaInsets.bottom, spacing.xl) },
					]}
					imageStyle={styles.containerRadius}
				>
					<View style={styles.closeIconContainer}>
						{onClose && (
							<ContainedAction
								type="close"
								onPress={handleOnClose}
								position="end"
							/>
						)}
					</View>

					<View style={styles.contentWrap}>
						<View style={styles.wrapper}>
							<CupStarGradientIcon size={iconSize.md} />
							<Text.CMSContent
								variant="heading_xs_upper"
								themeColor="content_neutral"
								contentVariables={contentVariables}
								value={supportLabel}
								style={[styles.text, styles.supportLabel]}
							/>
							{hasCompleted ? (
								<View style={styles.imageContainer}>
									{imageSource && (
										<Image
											source={{ uri: imageSource }}
											resizeMode="contain"
											style={styles.image}
										/>
									)}
									<View>
										<Chip
											title={chipContent}
											contentVariables={contentVariables}
											background="neutral_800"
										/>
									</View>
								</View>
							) : (
								<ChallengeProgressRing
									progress={progress}
									total={total}
									shouldShowOuterBackdrop={false}
								/>
							)}
						</View>
						<View style={styles.wrapper}>
							<Text
								variant="heading_md_upper"
								style={[styles.text, styles.title]}
							>
								{title}
							</Text>
							<Text.CMSContent
								variant="body_md"
								contentVariables={contentVariables}
								value={subtitle}
								style={styles.centerAlign}
							/>
						</View>
					</View>
					<View style={styles.buttonContainer}>
						<Button
							title={ctaTitle}
							onPress={onCtaPress}
							icon={ArrowRightIcon}
							iconPosition="right"
							size="fixed"
						/>
					</View>
				</ImageBackground>
			</View>
		</BottomSheet>
	);
};

const stylesheet = createStyleSheet(
	({ radius, colors, spacing, iconSize }) => ({
		container: {
			backgroundColor: colors.background_primary,
			borderTopLeftRadius: radius.xl,
			borderTopRightRadius: radius.xl,
			padding: spacing.xl,
		},
		text: {
			textAlign: 'center',
		},
		title: {
			lineHeight: 40,
		},
		image: {
			height: 139,
			width: 125,
		},
		supportLabel: {
			maxWidth: 176,
			lineHeight: 20,
		},
		contentWrap: {
			justifyContent: 'center',
			alignItems: 'center',
			gap: spacing.xl3,
			paddingHorizontal: spacing.xl2,
			paddingBottom: spacing.xl,
		},
		wrapper: {
			gap: spacing.md,
			justifyContent: 'center',
			alignItems: 'center',
		},
		centerAlign: {
			textAlign: 'center',
		},
		buttonContainer: {
			alignItems: 'center',
		},
		root: {
			position: 'relative',
		},
		containerRadius: {
			borderTopLeftRadius: radius.xl,
			borderTopRightRadius: radius.xl,
			backgroundColor: colors.content_neutral800,
		},
		closeIconContainer: {
			height: iconSize.lg,
		},
		imageContainer: {
			marginTop: spacing.xl,
			alignItems: 'center',
			gap: spacing.md,
		},
	}),
);

export default ChallengeProgressBottomSheet;
