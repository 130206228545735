import { HiInformationCircle } from '@react-icons/all-files/hi/HiInformationCircle';
import { getExtension } from '@sanity/asset-utils';
import { SanityImageObject } from '@sanity/image-url/lib/types/types';
import { Asset, defineType } from '@sanity/types';

import { previewBlockContent } from '../_utils';
import { InlineContentWrapperType } from './InlineContentWrapper';

export type MultipleContentCardBlockType = {
	_type: 'MultipleContentCardBlock';
	withBackground: boolean;
	options: {
		image: { asset: Asset };
		content: InlineContentWrapperType;
	}[];
};

export default defineType({
	name: 'MultipleContentCardBlock',
	type: 'object',
	icon: HiInformationCircle,
	fields: [
		{
			name: 'withBackground',
			type: 'boolean',
			initialValue: true,
		},
		{
			name: 'options',
			type: 'array',
			validation: (r) => r.required().min(1),
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'image',
							type: 'image',
							validation: (r) => {
								return r.custom<SanityImageObject>((value) => {
									if (value && getExtension(value.asset._ref) !== 'svg') {
										return 'Only SVG images are allowed';
									}
									return true;
								});
							},
						},
						{
							name: 'content',
							type: 'reference',
							to: [{ type: 'InlineContentWrapper' }],
							validation: (r) => r.required(),
						},
					],
					preview: previewBlockContent(),
				},
			],
		},
	],
	preview: {
		select: {
			options: 'options',
		},
		prepare: ({ options }) => {
			return {
				title: `${options.length} content cards`,
			};
		},
	},
});
