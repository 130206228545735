import { defineType } from '@sanity/types';

import { validateSnakeCase } from '../_utils';
import { ItoLLMConfigType } from '../ItoLLMConfig';

export type FragmentItoSessionSummarizationConfigType = Document & {
	_type: 'FragmentItoSessionSummarizationConfig';
	graph: string;
	threadPurposes: string[];
	nodeConfig: {
		sessionSummary: {
			llmConfig: ItoLLMConfigType;
		};
		safety: {
			llmConfig: ItoLLMConfigType;
		};
	};
};

export default defineType({
	name: 'FragmentItoSessionSummarizationConfig',
	type: 'document',
	fields: [
		{
			name: 'graph',
			type: 'string',
			initialValue: 'summarization',
			readOnly: true,
			description: 'The graph to use for this configuration',
			validation: (r) => r.required(),
		},
		{
			name: 'threadPurposes',
			type: 'array',
			of: [
				{
					type: 'string',
					validation: (r) => validateSnakeCase(r).required(),
				},
			],
			validation: (r) =>
				r.custom<string[]>((purposes) => {
					if (!purposes || purposes.length === 0) {
						return 'At least one thread purpose is required';
					}

					const uniquePurposes = new Set(purposes);
					if (uniquePurposes.size !== purposes.length) {
						return 'Thread purposes must be unique';
					}

					return true;
				}),
		},
		{
			name: 'nodeConfig',
			type: 'object',
			description: 'Configuration for the summarization nodes',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'sessionSummary',
					type: 'object',
					validation: (r) => r.required(),
					fields: [
						{
							name: 'llmConfig',
							type: 'reference',
							description: 'The LLM configuration for the session summary',
							to: [{ type: 'ItoLLMConfig' }],
							validation: (r) => r.required(),
						},
					],
				},
				{
					name: 'safety',
					type: 'object',
					validation: (r) => r.required(),
					fields: [
						{
							name: 'llmConfig',
							type: 'reference',
							description: 'The LLM configuration for the safety node',
							to: [{ type: 'ItoLLMConfig' }],
							validation: (r) => r.required(),
						},
					],
				},
			],
		},
	],
});
