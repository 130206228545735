import { useMemo } from 'react';

import { FragmentType } from '../../content/FragmentType';
import useGetChallenges from '../../store/api/hooks/useGetChallenges';
import { isNonNullable } from '../../utils/arrayUtils';
import { useActivities } from '../content/useActivities';
import { useFragment } from '../content/useFragment';
import { useActivityLockStatus } from '../useActivityLockStatus';

export const useChallengeById = (challengeId: string) => {
	const fragment = useFragment(FragmentType.Challenges);

	const challengeDetails = fragment.challenges.find(
		(challenge) => challenge.id === challengeId,
	);

	const { activityById } = useActivities();
	const { isLocked } = useActivityLockStatus();

	const { data: challengesData, isLoading, error } = useGetChallenges();

	const challenge = useMemo(() => {
		const acceptedChallenge = challengesData?.acceptedChallenges.find(
			(ch) => ch.id === challengeId,
		);

		if (!acceptedChallenge && !challengeDetails) return null;

		const activities = acceptedChallenge?.contributingActivityIds
			.map(activityById)
			.filter((activity) => isNonNullable(activity) && !isLocked(activity.id));

		const { completionsRequired, completionsDone } = acceptedChallenge ?? {};

		return {
			...challengeDetails,
			...acceptedChallenge,
			completionsRequired,
			completionsDone,
			activities,
		};
	}, [challengesData, challengeDetails, activityById, challengeId, isLocked]);

	return { challenge, isLoading, error };
};
