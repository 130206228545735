import * as ScreenCapture from 'expo-screen-capture';
import { useEffect } from 'react';

import { AnalyticsEvent, AnalyticsPayload } from '.';
import { useTrackEvent } from './trackEvent';

const useTrackScreenshot = () => {
	const trackEvent = useTrackEvent();

	useEffect(() => {
		let subscription: ScreenCapture.Subscription;

		const setupScreenshotListener = async () => {
			const { status } = await ScreenCapture.requestPermissionsAsync();
			const isGranted = status === 'granted';

			if (isGranted) {
				subscription = ScreenCapture.addScreenshotListener(() => {
					trackEvent(
						AnalyticsEvent.screenshot_taken,
						{} as AnalyticsPayload[AnalyticsEvent.screenshot_taken],
					);
				});
			}
		};

		setupScreenshotListener();

		return () => subscription?.remove();
	}, [trackEvent]);
};

export default useTrackScreenshot;
