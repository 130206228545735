import { ProgramActivityType } from '@wearemojo/sanity-schema';
import { useCallback, useMemo } from 'react';

import useCurrentTrackingDate from '../store/api/hooks/useCurrentTrackingDate';
import usePollResponses from '../store/api/hooks/usePollResponses';
import useUserActivity from '../store/api/hooks/useUserActivity';
import { getRuntimeInfo } from '../utils/buildInfo';
import platform from '../utils/platform';
import { useActivities } from './content/useActivities';
import { useEvaluateFeature } from './useEvaluateFeature';

type Props = {
	contextVariables?: Record<string, unknown>;
};

const useEvaluationArgs = () => {
	const currentTrackingDate = useCurrentTrackingDate();
	const { evaluateFeature, isLoading: isLoadingFeatures } =
		useEvaluateFeature();
	const { data: pollResponses, isFetching: isFetchingPolls } =
		usePollResponses();
	const { activities } = useActivities();
	const { userActivity } = useUserActivity();

	const pollAnswers = useMemo(
		() =>
			pollResponses?.reduce<Record<string, string[]>>((acc, item) => {
				acc[item.pollId] = item.optionKeys;
				return acc;
			}, {}),
		[pollResponses],
	);

	const programActivities = useMemo(
		() =>
			activities.reduce<Record<string, ProgramActivityType>>((acc, act) => {
				acc[act.id] = act;
				return acc;
			}, {}),
		[activities],
	);

	const getEvaluationArgs = useCallback(
		({ contextVariables }: Props) => {
			return {
				appPlatform: platform,
				appVersion: getRuntimeInfo().version,
				contextVariables,
				currentTrackingDate,
				evaluateFeature,
				pollAnswers,
				programActivities,
				userActivity,
			};
		},
		[
			currentTrackingDate,
			evaluateFeature,
			pollAnswers,
			programActivities,
			userActivity,
		],
	);

	return {
		getEvaluationArgs,
		isLoadingArgs: (isFetchingPolls && !pollResponses) || isLoadingFeatures,
	};
};

export default useEvaluationArgs;
