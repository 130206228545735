import { LinearGradient } from 'expo-linear-gradient';
import React, {
	forwardRef,
	PropsWithChildren,
	useImperativeHandle,
	useState,
} from 'react';
import {
	LayoutChangeEvent,
	Modal,
	Platform,
	Pressable,
	StyleSheet,
} from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BackgroundView from './BackgroundView';
import useAnimatedOverlay from './hooks/animations/useAnimatedOverlay';

type Ref = {
	toggleOverlay: () => void;
	dismiss: () => void;
	present: () => void;
};

type Props = PropsWithChildren<{
	onDismiss?: () => void;
	backdropPressDisabled?: boolean;
	linearGradientBackground?: boolean;
}>;

const BottomSheet = forwardRef<Ref, Props>(
	(
		{ onDismiss, backdropPressDisabled, linearGradientBackground, children },
		ref,
	) => {
		const [dynamicContentHeight, setDynamicContentHeight] = useState<number>(0);

		const {
			expanded,
			fullyClosed,
			toggleOverlay,
			dismiss,
			present,
			translateStyle,
			topStyle,
		} = useAnimatedOverlay({ contentHeight: dynamicContentHeight });

		useImperativeHandle(ref, () => {
			return {
				toggleOverlay,
				dismiss,
				present,
			};
		}, [dismiss, present, toggleOverlay]);

		const onLayout = (event: LayoutChangeEvent) => {
			setDynamicContentHeight(event.nativeEvent.layout.height);
		};

		const onBackdropPress = () => {
			if (backdropPressDisabled) {
				return;
			}
			toggleOverlay();
			onDismiss?.();
		};

		const {
			styles,
			theme: { colors },
		} = useStyles(stylesheet);

		return (
			<Modal visible={!fullyClosed} transparent statusBarTranslucent={true}>
				{expanded && (
					<Pressable style={StyleSheet.absoluteFill} onPress={onBackdropPress}>
						<Animated.View
							style={styles.flexOne}
							entering={FadeIn}
							exiting={FadeOut}
						>
							{linearGradientBackground ? (
								<LinearGradient
									colors={[
										`${colors.background_primary}00`,
										`${colors.background_primary}ff`,
									]}
									locations={[0, 0.5]}
									style={styles.flexOne}
								/>
							) : (
								<BackgroundView
									background="background_modal"
									style={styles.flexOne}
								/>
							)}
						</Animated.View>
					</Pressable>
				)}
				<Animated.View
					style={[styles.sheetContainer, topStyle, translateStyle]}
					onLayout={onLayout}
				>
					{children}
				</Animated.View>
			</Modal>
		);
	},
);

const stylesheet = createStyleSheet(() => ({
	flexOne: {
		flex: 1,
	},
	sheetContainer: {
		position: 'absolute',
		left: 0,
		right: 0,
		maxWidth: Platform.select({ web: 700, default: undefined }),
		marginHorizontal: Platform.select({ web: 'auto', default: undefined }),
	},
}));

export default BottomSheet;
