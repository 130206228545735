import { CiTextAlignCenter } from '@react-icons/all-files/ci/CiTextAlignCenter';
import { defineArrayMember, defineType } from '@sanity/types';

import CenterDecorator from '../../decorators/CenterDecorator';
import ContentVariable from '../fields/ContentVariable';
import Link from '../fields/Link';
import Accordion from './Accordion';
import AIMessageBlock from './AIMessageBlock';
import BillingBlock from './BillingBlock';
import Checklist from './Checklist';
import CheckoutPriceBlock from './CheckoutPriceBlock';
import ConceptBlock from './ConceptBlock';
import ContentCardBlock from './ContentCardBlock';
import ContentCardList from './ContentCardList';
import ContentSwitcherBlock from './ContentSwitcherBlock';
import EnableNotificationsButton from './EnableNotificationsButton';
import ExpertChip from './ExpertChip';
import ExpertQuote from './ExpertQuote';
import Flair from './Flair';
import Hr from './Hr';
import ImageWithAlignment from './ImageWithAlignment';
import InfoCardBlock from './InfoCardBlock';
import MemorableBlock from './MemorableBlock';
import MultipleContentCardBlock from './MultipleContentCardBlock';
import NumberedList from './NumberedList';
import ProgressTrail from './ProgressTrail';
import QuoteCardBlock from './QuoteCardBlock';
import ResearchCarousel from './ResearchCarousel';
import ScienceBlock from './ScienceBlock';
import TileWithExplanation from './TileWithExplanationList';
import TrackingButton from './TrackingButton';
import VideoKeyPoints from './VideoKeyPoints';
import WarningBlock from './WarningBlock';

export default defineType({
	name: 'BlockContent',
	type: 'array',
	of: [
		{
			type: 'block',
			marks: {
				annotations: [ContentVariable, Link],
				decorators: [
					{
						title: 'Center',
						value: 'center',
						icon: CiTextAlignCenter,
						// @ts-expect-error - the typing is wrong, this is a valid decorator
						component: CenterDecorator,
					},
					// These need to be left in as as soon as you add new marks the default marks are removed
					{ title: 'Strong', value: 'strong' },
					{ title: 'Emphasis', value: 'em' },
					{ title: 'Underline', value: 'underline' },
					{ title: 'Strike', value: 'strike' },
				],
			},
		},
		defineArrayMember(Accordion),
		defineArrayMember(AIMessageBlock),
		defineArrayMember(BillingBlock),
		defineArrayMember(Checklist),
		defineArrayMember(CheckoutPriceBlock),
		defineArrayMember(ConceptBlock),
		defineArrayMember(ContentCardBlock),
		defineArrayMember(ContentCardList),
		defineArrayMember(MultipleContentCardBlock),
		defineArrayMember(ContentSwitcherBlock),
		defineArrayMember(EnableNotificationsButton),
		defineArrayMember(ExpertChip),
		defineArrayMember(ExpertQuote),
		defineArrayMember(Flair),
		defineArrayMember(Hr),
		defineArrayMember(MemorableBlock),
		defineArrayMember(NumberedList),
		defineArrayMember(ProgressTrail),
		defineArrayMember(QuoteCardBlock),
		defineArrayMember(ScienceBlock),
		defineArrayMember(TileWithExplanation),
		defineArrayMember(TrackingButton),
		defineArrayMember(VideoKeyPoints),
		defineArrayMember(WarningBlock),
		defineArrayMember(InfoCardBlock),
		defineArrayMember(ResearchCarousel),
		defineArrayMember(ImageWithAlignment),
		{ type: 'image' },
		{
			type: 'reference',
			to: [
				{ type: 'Animation' },
				{ type: 'Audio' },
				{ type: 'Carousel' },
				{ type: 'Video' },
			],
		},
	],
});
