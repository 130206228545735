// When making changes here, be careful to maintain equivalence with v3 tasks

import { Temporal } from '@js-temporal/polyfill';
import { skipToken } from '@reduxjs/toolkit/query';
import { Service, ServiceResponses } from '@wearemojo/api-client';
import { DayTrackerProps } from '@wearemojo/ui-components';
import { useCalendars } from 'expo-localization';
import * as Notifications from 'expo-notifications';
import { useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';

import { AnalyticsEvent } from '../../../analytics/AnalyticsEvent';
import { useTrackEvent } from '../../../analytics/trackEvent';
import useEndpointQuery from '../../../hooks/queries/useEndpointQuery';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useLocalization } from '../../../hooks/useLocalization';
import useUserActivityDates from '../../../hooks/useUserActivityDates';
import { selectUserId } from '../../session';
import api from '..';
import useCurrentTrackingDate from './useCurrentTrackingDate';
import useUserActivity from './useUserActivity';

export type TaskItem =
	ServiceResponses[Service.dailytask]['getTasks']['items'][number];

type UserActivity =
	ServiceResponses[Service.learntracking]['listUserActivity'][number];

type DateRange = {
	startDate: Temporal.PlainDate;
	endDate: Temporal.PlainDate;
};

type TasksDate = DayTrackerProps['dates'][0] & {
	items?: TaskItem[];
};

export const useTodayTasks = () => {
	const userId = useAppSelector(selectUserId);
	const { timezone } = useLocalization();

	return useEndpointQuery(
		api.endpoints.getTasks.useQuery(
			userId
				? { userIdentifier: { type: 'user', value: userId }, timezone }
				: skipToken,
			// refetch on focus to invalidate data for app being open for too long
			{ refetchOnFocus: true },
		),
	);
};

export const useDateStates = () => {
	const userId = useAppSelector(selectUserId);
	const { timezone } = useLocalization();

	return useEndpointQuery(
		api.endpoints.listDateStates.useQuery(
			userId ? { userId, timezone } : skipToken,
			// refetch on focus to invalidate data for app being open for too long
			{ refetchOnFocus: true },
		),
	);
};

const useFirstWeekday = () => {
	const { firstWeekday } = useCalendars()[0] ?? {};
	if (!firstWeekday) return 1; // Monday

	// convert from expo-localization to Temporal
	return firstWeekday === 1 ? 7 : firstWeekday - 1;
};

type WeekForDateOptions = { firstWeekday: number };
const getWeekForDate = (
	date: Temporal.PlainDate,
	{ firstWeekday }: WeekForDateOptions,
): DateRange => {
	let daysToSubtract = date.dayOfWeek - firstWeekday;
	if (daysToSubtract < 0) daysToSubtract += 7;

	const startDate = date.subtract({ days: daysToSubtract });
	const endDate = startDate.add({ days: 6 });

	return { startDate, endDate };
};

const clampDate = (
	date: Temporal.PlainDate,
	minDate: Temporal.PlainDate,
	maxDate: Temporal.PlainDate,
) => {
	const minDiff = Temporal.PlainDate.compare(date, minDate);
	if (minDiff < 0) return minDate;

	const maxDiff = Temporal.PlainDate.compare(date, maxDate);
	if (maxDiff > 0) return maxDate;

	return date;
};

const dedupeItems = (items: TaskItem[]): TaskItem[] => {
	const seen = new Set<string>();

	return items.filter((item) => {
		const key = `${item.type}/${(() => {
			switch (item.type) {
				case 'activity':
					return item.params.activityId;
				default:
					((_: never) => {
						throw new Error(`Unexpected task type: ${JSON.stringify(item)}`);
					})(item.type);
			}
		})()}`;
		return seen.has(key) ? false : seen.add(key);
	});
};

const userActivityToTaskItem = (
	activity: UserActivity,
): TaskItem | undefined => {
	if (activity.type !== 'activity_completed') return;

	return {
		type: 'activity',
		completed: true,
		params: activity.params,
	};
};

export const useHomeTasks = () => {
	const firstWeekday = useFirstWeekday();
	const [selectedDate, setSelectedDate] = useState<Temporal.PlainDate>();
	const currentTrackingDate = useCurrentTrackingDate();
	const { data: todayTasks } = useTodayTasks();
	const trackEvent = useTrackEvent();
	const dateStates = useDateStates().data;
	const activityCompletionDates =
		useUserActivityDates().activityCompletionDates;

	useEffect(() => {
		if (Platform.OS === 'web') return;
		if (!todayTasks) return;

		const tasksToComplete = 1;

		const completedTasks = todayTasks.items.filter(
			(item) => item.completed,
		).length;

		const incompleteTodayTasks = Math.max(tasksToComplete - completedTasks, 0);
		Notifications.setBadgeCountAsync(incompleteTodayTasks);
	}, [todayTasks]);

	useEffect(() => {
		setSelectedDate(currentTrackingDate);
	}, [currentTrackingDate]);

	const { userActivity, firstQueryableDate } = useUserActivity();

	const week = useMemo(
		() => selectedDate && getWeekForDate(selectedDate, { firstWeekday }),
		[firstWeekday, selectedDate],
	);

	const isDateReachable = (date?: Temporal.PlainDate) => {
		if (!firstQueryableDate || !currentTrackingDate || !date) return;
		const clampedDate = clampDate(
			date,
			firstQueryableDate,
			currentTrackingDate,
		);
		return clampedDate.equals(date);
	};

	if (!selectedDate && currentTrackingDate) {
		setSelectedDate(currentTrackingDate);
	}

	if (selectedDate && isDateReachable(selectedDate) === false) {
		setSelectedDate(currentTrackingDate);
	}

	const todayTasksCompleted =
		currentTrackingDate && todayTasks?.items.every((item) => item.completed);

	const isFirstDay =
		currentTrackingDate && firstQueryableDate?.equals(currentTrackingDate);

	const dates: TasksDate[] | undefined = useMemo(() => {
		if (
			!week ||
			!firstQueryableDate ||
			!currentTrackingDate ||
			!userActivity ||
			!todayTasks
		) {
			return;
		}

		const result: TasksDate[] = [];

		// Always keep the current date in the middle of the list
		const startOfUserWeek = currentTrackingDate?.subtract({ days: 3 });
		const endOfUserWeek = startOfUserWeek?.add({ days: 6 });

		for (
			let trackingDate = startOfUserWeek;
			Temporal.PlainDate.compare(trackingDate, endOfUserWeek) <= 0;
			trackingDate = trackingDate.add({ days: 1 })
		) {
			const dateCompleted = !!activityCompletionDates?.find((ds) =>
				Temporal.PlainDate.from(ds).equals(trackingDate),
			);

			const isCurrentDate = trackingDate.equals(currentTrackingDate);

			if (isCurrentDate) {
				result.push({
					trackingDate,
					items: todayTasks.items,
					state: dateCompleted ? 'complete' : 'current_day',
				});
				continue;
			}

			const dateItems: TaskItem[] = dedupeItems(
				userActivity
					.filter((item) =>
						Temporal.PlainDate.from(item.trackingDate).equals(trackingDate),
					)
					.map(userActivityToTaskItem)
					.flatMap((item) => (item ? [item] : [])),
			);

			const checkUnreachable = () => {
				if (Temporal.PlainDate.compare(trackingDate, currentTrackingDate) > 0)
					return true;
				if (Temporal.PlainDate.compare(trackingDate, firstQueryableDate) < 0)
					return true;
				return false;
			};

			const getTaskState = () => {
				if (dateCompleted) return 'complete';
				if (checkUnreachable()) return 'unreachable';
				return 'incomplete';
			};

			result.push({
				trackingDate,
				items: dateItems,
				state: getTaskState(),
			});
		}

		return result;
	}, [
		week,
		firstQueryableDate,
		currentTrackingDate,
		userActivity,
		todayTasks,
		activityCompletionDates,
	]);

	const onDayPickerPress = (date: Temporal.PlainDate) => {
		trackEvent(AnalyticsEvent.day_picker_pressed, {});
		setSelectedDate(date);
	};

	return {
		dates,
		dateStates,
		currentTrackingDate,
		selectedDate,
		setSelectedDate,
		todayTasksCompleted,
		isFirstDay,
		onDayPickerPress,
	};
};
