import { BrandColor } from '@wearemojo/ui-constants';

import { ButtonType } from './types';

export const abstractOverpanelSheetPatterns = [
	'pattern_1',
	'pattern_2',
	'pattern_3',
	'pattern_4',
	'pattern_5',
	'pattern_6',
	'pattern_7',
] as const;

export type AbstractOverpanelSheetPattern =
	(typeof abstractOverpanelSheetPatterns)[number];

export const patternMapping = {
	pattern_1: () => require('../assets/patterns/pattern_1.png'),
	pattern_2: () => require('../assets/patterns/pattern_2.png'),
	pattern_3: () => require('../assets/patterns/pattern_3.png'),
	pattern_4: () => require('../assets/patterns/pattern_4.png'),
	pattern_5: () => require('../assets/patterns/pattern_5.png'),
	pattern_6: () => require('../assets/patterns/pattern_6.png'),
	pattern_7: () => require('../assets/patterns/pattern_7.png'),
} satisfies Record<AbstractOverpanelSheetPattern, () => NodeRequire>;

export const buttonPatternMapping = {
	pattern_1: ButtonType.primary,
	pattern_2: ButtonType.secondary,
	pattern_3: ButtonType.secondary,
	pattern_4: ButtonType.secondary,
	pattern_5: ButtonType.secondary,
	pattern_6: ButtonType.primary,
	pattern_7: ButtonType.secondary,
} satisfies Record<AbstractOverpanelSheetPattern, ButtonType>;

export const chipBackgroundPatternMapping = {
	pattern_1: 'primary_yellow_lighter',
	pattern_2: 'primary_green_lighter',
	pattern_3: 'primary_purple_lighter',
	pattern_4: 'primary_blue_lighter',
	pattern_5: 'primary_yellow_lighter',
	pattern_6: 'neutral_300',
	pattern_7: 'primary_purple_lighter',
} satisfies Record<AbstractOverpanelSheetPattern, keyof typeof BrandColor>;
