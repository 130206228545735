import { ISO8601DateTime } from '../types';

export const serviceVersion = '2024-12-18';

// This service is a shim around LangGraph Cloud -- simply adding user_id for now.
// Check out https://langchain-ai.github.io/langgraph/cloud/reference/api/api_ref.html
export type ServiceParams = {
	createLanggraphThread: {
		userId: string;
		metadata: {
			trackingDate: ISO8601DateTime;
			threadPurpose: string;
			threadKey: string;
			threadMode: 'one_off' | 'tracking_date' | 'singleton';
		};
	};
	searchLanggraphThreads: {
		userId: string;
	};
	runLanggraphThread: {
		userId: string;
		threadId: string;
		assistantId: string;
		input: Record<string, unknown>;
		config: {
			configurable: {
				userTimezone: string;
				assistantConfig: string;
				[key: string]: unknown;
			};
			[key: string]: unknown;
		};
	};
	listMemories: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
	};
	upsertMemory: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		memoryId?: string;
		content: string;
		context: string;
		timezone: string;
		runId: string | null;
	};
	deleteMemory: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		memoryId: string;
	};
};

export type ServiceResponses = {
	createLanggraphThread: LanggraphThread;
	searchLanggraphThreads: LanggraphThread[];
	runLanggraphThread: RunLanggraphThead;
	listMemories: Memory[];
	upsertMemory: void;
	deleteMemory: void;
};

export type LanggraphThread = {
	threadId: string;
	createdAt: ISO8601DateTime;
	updatedAt: ISO8601DateTime;
	metadata: {
		trackingDate: ISO8601DateTime;
		threadPurpose: string;
		threadKey: string;
	};
	status: 'idle' | 'busy' | 'interrupted' | 'error';
	values: {
		messages: RunMessage[] | Record<string, unknown>[];
	} | null;
};

export type RunMessage = BaseMessage &
	(ToolMessage | AIMessage | HumanMessage | SystemMessage);

type BaseMessage = {
	additionalKwargs: Record<string, unknown>;
	content: string;
	id: string;
};

type ToolMessage = {
	name: string;
	responseMetadata: Record<string, unknown>;
	toolCallId: string;
	type: 'tool';
};

type AIMessage = {
	responseMetadata: Record<string, unknown>;
	toolCallChunks: Record<string, unknown>[];
	toolCalls: Record<string, unknown>[];
	type: 'ai';
};

type HumanMessage = {
	responseMetadata: Record<string, unknown>;
	type: 'human';
};

type SystemMessage = {
	toolCallChunks: Record<string, unknown>[];
	toolCalls: Record<string, unknown>[];
	type: 'system';
};

type RunError = {
	error: string;
	message: string;
};

type RunLanggraphThead = {
	messages: RunMessage[];
	__error__: RunError;
};

type Memory = {
	memoryId: string;
	content: string;
	context: string;
};
