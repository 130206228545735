import { Temporal } from '@js-temporal/polyfill';
import { useMemo } from 'react';

import useUserActivity from '../store/api/hooks/useUserActivity';

type ActivityDate = {
	activeDates: Set<string>;
	recoveredDates: Set<string>;
};

const useUserActivityDates = () => {
	const { userActivity, isFetchingUserActivity } = useUserActivity();

	const activityCompletion = useMemo(() => {
		if (!userActivity) return;

		const datesSet = userActivity.reduce<ActivityDate>(
			(acc, { type, trackingDate, params }) => {
				switch (type) {
					case 'activity_completed':
						acc.activeDates.add(trackingDate);
						break;
					case 'streak_rest_days_arranged':
						params.restDays.forEach(({ trackingDate: date }) => {
							acc.recoveredDates.add(date);
						});
						break;
				}
				return acc;
			},
			{ activeDates: new Set(), recoveredDates: new Set() },
		);

		return {
			activityCompletionDates: Array.from(datesSet.activeDates).sort(
				Temporal.PlainDate.compare,
			),
			recoveredDates: Array.from(datesSet.recoveredDates).sort(
				Temporal.PlainDate.compare,
			),
		};
	}, [userActivity]);

	return {
		...activityCompletion,
		isFetchingUserActivity,
	};
};

export default useUserActivityDates;
