import {
	AUDIO_FRAGMENT,
	BLOCK_CONTENT_FRAGMENT,
	HELP_FRAGMENT,
} from './fragments';

// This query gets dereferenced automatically - any `_ref` field that can't be
// resolved will result in error logs. To opt out of automatic dereferencing,
// map to another field name - e.g. `flow { "__ref": _ref }`.
export const FRAGMENT_DOCUMENTS_QUERY = `*[_type match "Fragment*"] {
	...,
	_type == 'FragmentCommunityExpertQuotes' => {
		expertQuotes[] {
			...,
			expert-> {
				...,
				image {
					asset->,
				},
			},
		},
	},
	_type == 'FragmentFlows' => {
		registrationPrefaceFlow { "__ref": _ref },
		checkoutPrefaceFlow { "__ref": _ref },
		welcomeFlow { "__ref": _ref },
	},
	_type == 'FragmentProgram' => {
		items[] {
			items[]-> {
				...,
				cardImage {
					asset->,
				},
				activityImage {
					asset->,
					hotspot,
					crop,
				},
				stagingContent {
					...,
					content[] {
						${BLOCK_CONTENT_FRAGMENT}
					},
				},
				learning {
					...,
					flow { "__ref": _ref },
				},
				variants[] {
					...,
					flow { "__ref": _ref },
				},
				defaultVariant {
					...,
					flow { "__ref": _ref },
				},
			},
		},
	},
	_type == 'FragmentGoalCard' => {
		goals[] {
			...,
			image {
				asset->,
			},
		},
	},
	_type == 'FragmentStreaks' => {
		streaksCommitment {
			...,
			poll->,
		},
	},
	_type == 'FragmentSQScorePillars' => {
		...,
		scorePillars {
			...,
			connection_building {
				...,
				pillarImage { asset-> }, rewardImage { asset-> },
			},
			mental_resilience {
				...,
				pillarImage { asset-> }, rewardImage { asset-> },
			},
			sexual_fitness {
				...,
				pillarImage { asset-> }, rewardImage { asset-> },
			},
			stimulation_response {
				...,
				pillarImage { asset-> }, rewardImage { asset-> },
			},
		}
	},
	_type == 'FragmentRefer' => {
		...,
		title {
			_type == 'reference' => @-> {
				...,
			}
		},
		content {
			_type == 'reference' => @-> {
				...,
			}
		},
		referTitle {
			_type == 'reference' => @-> {
				...,
			}
		},
		discountText {
			_type == 'reference' => @-> {
				...,
			}
		},
	},
	_type == 'FragmentCheckoutSubscriptionPlans' => {
		...,
		beforeYouGo {
			...,
			title->,
			content->,
		},
	},
	_type == 'FragmentExplore' => {
		...,
		lockedModal {
			...,
			content->,
		},
	},
	_type == 'FragmentOneActivityADay' => {
		...,
		getAnotherActivityContent {
			...,
			description->,
		},
		completedActivityContent {
			...,
			description->,
		},
	},
	_type == 'FragmentCelebrateCheckout' => {
		...,
		content->,
	},
	_type == 'FragmentCoinEducation' => {
		title->,
		description->,
	},
	_type == 'FragmentEarnedCoins' => {
		title->,
		description->,
		image {
			asset->,
		},
	},
	_type == 'FragmentCoin' => {
		image {
			asset->,
		},
	},
	_type == 'FragmentChallenges' => {
		...,
		challenges[]->{
			...,
			contributingActivities[] {
				...,
				_type == 'ProgramActivityFilterExactActivity' => {
					activity-> {
						id,
					},
				},
			},
			offeringCondition[] {
				...,
				_type == 'reference' => @-> {
					...,
					filters[] {
						...,
						activity->,
					},
				},
				operands[] {
					...,
					_type == 'reference' => @->,
				},
			},
		},
	},
	_type == 'FragmentStreaksRecovery' => {
		beforeRecovery {
			...,
			title->,
			content->,
			coinBalanceContent->,
			ctaText->,
		},
		afterRecovery {
			...,
			title->,
			content->,
			coinBalanceContent->,
			ctaText->,
		},
	},
	_type == 'FragmentItoChat' => {
		homeScreenConfigCases[] {
			...,
			assistantConfig-> {
				...,
				agentConfig {
					...,
					chat {
						...,
						toolOptions {
							...,
							allowedPolls[]-> { id },
						},
					},
				},
			},
		},
		homeScreenConfigDefault-> {
			...,
			agentConfig {
				...,
				chat {
					...,
					toolOptions {
						...,
						allowedPolls[]-> { id },
					},
				},
			},
		},
	},
}`;

export const ERROR_MESSAGES_QUERY = `*[_id == "singleton-ErrorMessages"][0]`;

export const SOUND_SERIES_QUERY = `*[_type == "SoundSeries"] {
	...,
	episodes[]-> {
		...,
		audio-> {
			${AUDIO_FRAGMENT}
		}
	}
}`;

export const MEDITATIONS_QUERY = `*[_type == "Meditation"] {
	...,
	series[]-> {
		...,
		episodes[]->
	}
}[0]`;

export const POLL_QUERY = `*[_type == "Poll"] {
	...,
	options[] {
		...,
		_type == 'PollOption' => {
			...,
			text,
		},
	},
}`;

export const THERAPY_SESSIONS_QUERY = `*[_type == "TherapySession"] {
	...,
	series[]-> {
		...,
		episodes[]->
	}
}[0]`;

export const AUDIO_PORN_QUERY = `*[_type == "AudioPorn"] {
	...,
	series[]-> {
		...,
		episodes[]->
	},
}[0]`;

export const EFFICACY_QUESTION_SET_QUERY =
	'*[_type == "EfficacyQuestionSetVersion" && version == $version][0]';

// This query gets dereferenced automatically - any `_ref` field that can't be
// resolved will result in error logs. To opt out of automatic dereferencing,
// map to another field name - e.g. `flow { "__ref": _ref }`.
export const EXPRESSION_DOCUMENTS_QUERY = `
	*[_type match 'Expression*'] {
		...,
		_type == 'ExpressionPollAnswerKeys' => {
			poll-> {
				id,
			},
		},
		_type in [
			'ExpressionProgramActivityCompleted',
			'ExpressionProgramActivityNotCompleted',
		] => {
			...,
			filters[] {
				...,
				_type == 'ProgramActivityFilterExactActivity' => {
					activity-> {
						id,
					},
				},
			},
		},
	}
`;

// This query gets dereferenced automatically - any `_ref` field that can't be
// resolved will result in error logs. To opt out of automatic dereferencing,
// map to another field name - e.g. `flow { "__ref": _ref }`.
export const FLOW_DOCUMENTS_QUERY = `
	*[
		_type == 'Flow'
		|| _type == 'FlowGroup'
		|| _type match 'Flow*Step'
	] {
		...,
		content[] {
			${BLOCK_CONTENT_FRAGMENT}
		},
		contentAppend[] {
			${BLOCK_CONTENT_FRAGMENT}
		},
		footerContent[] {
			${BLOCK_CONTENT_FRAGMENT}
		},
		_type == 'FlowPollStep' => {
			poll->,
			headerImage {
				asset->,
			},
		},
		_type == 'FlowPollSliderStep' => {
			poll->,
		},
		_type == 'FlowPollQuestionAnswerStep' => {
			poll->,
			selectedPollContent,
		},
		_type == 'FlowNotificationsPreferenceStep' => {
			image {
				asset->,
			},
		},
		_type == 'FlowContentStep' => {
			headerImage {
				asset->,
			},
			backgroundImage {
				asset->,
			},
		},
		_type == 'FlowItoTherapistDetailsStep' => {
			headerImage {
				asset->,
			},
			backgroundImage {
				asset->,
			},
		},
		_type == 'FlowItoChatStep' => {
			assistantConfig-> {
				...,
				agentConfig {
					...,
					chat {
						...,
						toolOptions {
							...,
							allowedPolls[]-> { id },
						},
					},
				},
			},
		},
		_type == 'FlowExerciseTimedImageStep' => {
			image {
				asset->,
			},
		},
		_type == 'FlowExerciseRESupportStep' => {
			pages[] {
				...,
				content[] {
					${BLOCK_CONTENT_FRAGMENT}
				},
				lottieFile {
					asset->,
				},
			},
		},
		_type == 'FlowAudioPlayerStep' => {
			...,
			audio-> {
				${AUDIO_FRAGMENT}
			},
			playerImage {
				asset->,
			}
		},
		_type == 'FlowFullScreenVideoStep' => {
			...,
			audio {
				asset->,
			},
			overpanelHelpSection-> {
				${HELP_FRAGMENT}
			},
		},
		_type == 'FlowPelvicExerciseStep' => {
			...,
			backgroundAudio {
				asset->,
			},
			rest {
				...,
				audio-> {
					${AUDIO_FRAGMENT}
				},
			},
			squeeze {
				...,
				audio-> {
					${AUDIO_FRAGMENT}
				},
			},
			overpanelHelpSection-> {
				${HELP_FRAGMENT}
			},
		},
		_type == 'FlowReverseKegelExerciseStep' => {
			...,
			backgroundAudio {
				asset->,
			},
			rest {
				...,
				audio-> {
					${AUDIO_FRAGMENT}
				},
			},
			squeeze {
				...,
				audio-> {
					${AUDIO_FRAGMENT}
				},
			},
			overpanelHelpSection-> {
				${HELP_FRAGMENT}
			},
		},
		_type == 'FlowExerciseTimedBoxBreathAnimationStep' => {
			...,
			inhale {
				...,
				audio { asset-> },
			},
			hold {
				...,
				audio { asset-> },
			},
			exhale {
				...,
				audio { asset-> },
			},
			overpanelHelpSection-> {
				${HELP_FRAGMENT}
			},
		},
		_type == 'FlowPelvicFloorExerciseCompleteStep' => {
			...,
			progressRingImage {
				asset->,
			},
		},
		_type == 'FlowOnboardingActivityStep' => {
			sheet {
				...,
				image { asset-> },
			},
		},
		_type == 'FlowItoTextStep' => {
			...,
			mode->{
				...,
				visualizeSummaryContent->{
					content[] {
						${BLOCK_CONTENT_FRAGMENT}
					},
				},
			},
			footerContent->{
				${BLOCK_CONTENT_FRAGMENT}
			},
		},
		_type == 'FlowItoInnerCriticVisualizeStep' => {
			title -> {
				content[] {
					${BLOCK_CONTENT_FRAGMENT}
				},
			},
			content->{
				content[] {
					${BLOCK_CONTENT_FRAGMENT}
				},
			},
			mode->{
				...,
				visualizeSummaryContent->{
					content[] {
						${BLOCK_CONTENT_FRAGMENT}
					},
				},
			},
		},
		_type == 'FlowOnboardingGoalsStep' => {
			poll->,
		},
	}
`;
