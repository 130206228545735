import { PortableTextComponentProps } from '@portabletext/react';
import { MultipleContentCardBlockType } from '@wearemojo/sanity-schema';

import MultipleContentCard from '../cards/MultipleContentCard';
import CMSBlock from './CMSBlock';

type Props = PortableTextComponentProps<MultipleContentCardBlockType>;
const CMSMultipleContentCardBlock = ({ value }: Props) => {
	return (
		<CMSBlock textAlign="auto">
			<MultipleContentCard
				options={value.options}
				withBackground={value.withBackground}
			/>
		</CMSBlock>
	);
};

export default CMSMultipleContentCardBlock;
