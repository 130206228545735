import { TbPrompt } from '@react-icons/all-files/tb/TbPrompt';
import { defineType } from '@sanity/types';

enum Model {
	OpenAIGPT4o = 'openai/gpt-4o',
	OpenAIGPT4oMini = 'openai/gpt-4o-mini',
	OpenAIO1 = 'openai/o1',
	OpenAIO1Mini = 'openai/o1-mini',
	OpenAIO1Preview = 'openai/o1-preview',
}

export type ItoLLMConfigType = {
	_type: 'ItoLLMConfig';
	promptName: string;
	model: `${Model}`;
	temperature: number;
};

export default defineType({
	name: 'ItoLLMConfig',
	type: 'document',
	icon: TbPrompt,
	fields: [
		{
			name: 'promptName',
			type: 'string',
			description:
				'The name of the prompt to use for this agent (e.g., "live-homescreen-chat" for chat agent, "live-homescreen-safety" for safety agent)',
			validation: (r) => r.required(),
		},
		{
			name: 'model',
			type: 'string',
			description: 'The LLM model for this agent',
			validation: (r) => r.required(),
			options: {
				list: Object.values(Model).map((model) => ({
					title: model,
					value: model,
				})),
			},
		},
		{
			name: 'temperature',
			type: 'number',
			description: 'The temperature for the LLM',
			validation: (r) => r.required().min(0).max(2),
			initialValue: 1,
		},
	],
	preview: {
		select: {
			title: 'promptName',
			subtitle: 'model',
		},
	},
});
