import { defineType } from '@sanity/types';

import {
	arrayOfTypesBoolean,
	ExpressionConditionalBooleanType,
} from './_types';
import { renderExpression } from './_utils';

export default defineType({
	name: 'ExpressionConditionalBoolean',
	title: 'Conditional Condition',
	type: 'object',
	fields: [
		{
			name: 'cases',
			type: 'array',
			description:
				'To override the default value - the first matching case will be used',
			validation: (r) => r.required().min(1),
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'conditionOperand',
							title: 'Condition',
							type: 'array',
							description: 'Condition to determine if this case should be used',
							validation: (r) => r.required().length(1),
							of: arrayOfTypesBoolean,
						},
						{
							name: 'valueOperand',
							title: 'Value',
							type: 'array',
							description: 'Value if this case is used',
							validation: (r) => r.required().length(1),
							of: arrayOfTypesBoolean,
						},
					],
				},
			],
		},
		{
			name: 'defaultValueOperand',
			title: 'Default Value',
			type: 'array',
			validation: (r) => r.required().length(1),
			of: arrayOfTypesBoolean,
		},
	],
	preview: {
		select: {
			_type: '_type',
			cases: 'cases',
			defaultValueOperand: 'defaultValueOperand',
		},
		prepare: (expression: ExpressionConditionalBooleanType) => ({
			title: renderExpression(expression),
		}),
	},
});
