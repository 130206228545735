import { HiOutlineDocumentText } from '@react-icons/all-files/hi/HiOutlineDocumentText';
import { defineType } from '@sanity/types';

import { Image } from '../_types';
import { previewNamedBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { FlowBasePageType } from './baseTypes';

export type FlowContentStepType = FlowBasePageType & {
	_type: 'FlowContentStep';
	hideProgressBar?: boolean;
	content: BlockContentType;
	headerImage?: Image;
	headerImageAsBackground?: boolean;
	backgroundImage?: Image;
	contentAppend?: BlockContentType;
};

export default defineType({
	name: 'FlowContentStep',
	type: 'document',
	icon: HiOutlineDocumentText,
	fields: [
		{
			name: 'icon',
			type: 'Icon',
		},
		{
			name: 'headerImageAsBackground',
			type: 'boolean',
			description:
				"Note: Don't use these within the onboarding flows. If true, the header image will be used as a background image otherwise as a full width image.",
			initialValue: false,
		},
		{
			name: 'hideProgressBar',
			type: 'boolean',
			description: 'If true, the progress bar will not be shown on the screen.',
			initialValue: false,
		},
		{
			name: 'headerImage',
			type: 'image',
			description:
				'Header image for the step. Cannot be set if a background image is used.',
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.length(1),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'contentAppend',
			type: 'BlockContent',
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
		{
			name: 'backgroundImage',
			type: 'image',
			description:
				'Optional background image for the entire step content. Cannot be set if a header image is used.',
		},
	],
	preview: previewNamedBlockContent('title'),
	validation: (r) => [
		r.custom((fields) => {
			if (fields?.headerImage && fields?.backgroundImage) {
				return 'You cannot set both a header image and a background image.';
			}
			return true;
		}),
	],
});
