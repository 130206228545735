import { HiOutlineChat } from '@react-icons/all-files/hi/HiOutlineChat';
import { defineType } from '@sanity/types';

import { Image } from '../_types';
import { previewNamedBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { FlowBaseStepType } from './baseTypes';

export type FlowCommunityTopicStepType = FlowBaseStepType & {
	_type: 'FlowCommunityTopicStep';
	content: BlockContentType;
	topicId: string;
	contentAppend?: BlockContentType;
	headerImage?: Image;
};

export default defineType({
	name: 'FlowCommunityTopicStep',
	type: 'document',
	icon: HiOutlineChat,
	fields: [
		{
			name: 'icon',
			type: 'Icon',
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.length(1),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'topicId',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'contentAppend',
			type: 'BlockContent',
		},
		{
			name: 'headerImage',
			type: 'image',
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
	],
	preview: previewNamedBlockContent('topicId', 'content'),
});
