/*
 * The KeyboardAvoidingView stub implementation for web where the keyboard is handled automatically.
 */
import React from 'react';
import { View } from 'react-native';

import type { KeyboardAvoidingViewProps } from './types';

const KeyboardAvoidingView = (props: KeyboardAvoidingViewProps) => {
	return <View {...props} />;
};

export default KeyboardAvoidingView;
