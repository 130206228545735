import { HiOutlineDocumentText } from '@react-icons/all-files/hi/HiOutlineDocumentText';
import { defineType } from '@sanity/types';

import { previewNamedBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { InlineContentType } from '../block/InlineContent';
import { FlowBasePageType } from './baseTypes';

export type FlowInputStepType = FlowBasePageType & {
	_type: 'FlowInputStep';
	title: InlineContentType;
	content: BlockContentType;
	inputFieldLabel: string;
	maxInputLength: number;
	multiline: boolean;
	enableSuggestions: boolean;
} & (SaveMemory | NoSaveMemory);

type SaveMemory = {
	saveAsMemory: true;
	memoryContent: string;
	memoryContext: string;
};

type NoSaveMemory = {
	saveAsMemory: false;
	memoryContent?: never;
	memoryContext?: never;
};

export default defineType({
	name: 'FlowInputStep',
	type: 'document',
	icon: HiOutlineDocumentText,
	fields: [
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.length(1),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'inputFieldLabel',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'maxInputLength',
			type: 'number',
			title: 'Max input length',
			description: 'Maximum number of characters the user can input.',
			initialValue: 30,
		},
		{
			name: 'multiline',
			type: 'boolean',
			title: 'Multiline input',
			description: 'Allow the user to input multiple lines of text.',
			initialValue: false,
		},
		{
			name: 'enableSuggestions',
			type: 'boolean',
			title: 'Enable suggestions',
			description: 'Enable keyboard suggestions for the input field.',
			initialValue: true,
		},
		{
			name: 'saveAsMemory',
			type: 'boolean',
			title: 'Save input as memory',
			initialValue: false,
		},
		{
			name: 'memoryContent',
			type: 'string',
			title: 'Memory Content',
			description:
				'Content prefix for the memory. This will be combined with user input in format "prefix: user input". ' +
				'Example: If you set this to "Cheese preference" and user answers "I love cheese", ' +
				'it will be stored as "Cheese preference: I love cheese". ' +
				'Keep it short and descriptive as it helps categorize the memory.',
			hidden: ({ parent }) => !parent?.saveAsMemory,
			validation: (r) =>
				r.custom((value, context) => {
					const { saveAsMemory } = context.parent as FlowInputStepType;

					if (saveAsMemory && !value) {
						return 'Memory context is required when saving as memory';
					}

					return true;
				}),
		},
		{
			name: 'memoryContext',
			type: 'string',
			title: 'Memory Context',
			description:
				'Context for the memory, if saving as memory. This will not be shown to the user only the AI will see it.',
			hidden: ({ parent }) => !parent?.saveAsMemory,
			validation: (r) =>
				r.custom((value, context) => {
					const { saveAsMemory } = context.parent as FlowInputStepType;

					if (saveAsMemory && !value) {
						return 'Memory context is required when saving as memory';
					}

					return true;
				}),
		},
		{
			name: 'nextCtaText',
			type: 'string',
			title: 'Next CTA Text',
			description:
				'Text for the next button. If the user has not filled in the input, it will be "Skip".',
			initialValue: 'Next',
		},
	],
	preview: previewNamedBlockContent('title'),
});
