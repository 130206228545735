import { PelvicFloorExerciseParams } from '@wearemojo/api-client/src/services/learntracking';
import Cher from '@wearemojo/cher';

import { SetWhoopsOptions } from '../../errors/WhoopsProvider';
import { logger } from '../logging';

export const isPelvicFloorExerciseParams = (
	obj: any,
): obj is PelvicFloorExerciseParams => {
	return (
		obj &&
		typeof obj === 'object' &&
		typeof obj.reps === 'number' &&
		typeof obj.duration === 'number' &&
		typeof obj.expectedReps === 'number' &&
		typeof obj.expectedDuration === 'number'
	);
};

export const logParseError = (
	paramName: string,
	error: Error | unknown,
	whoops: (_cher: Cher | null, _options?: SetWhoopsOptions) => void,
) => {
	logger.captureError(`Failed to parse ${paramName}`, { error });
	whoops(
		new Cher('invalid_pelvic_floor_params', {
			message: `Failed to parse ${paramName}`,
		}),
	);
};

export const parsePelvicFloorParams = (
	whoops: (_cher: Cher | null, _options?: SetWhoopsOptions) => void,
	pelvicFloorExerciseParams?: string,
) => {
	const params: {
		pelvicFloorExerciseParams?: PelvicFloorExerciseParams;
	} = {};

	let parseError = false;

	if (pelvicFloorExerciseParams) {
		try {
			params.pelvicFloorExerciseParams = JSON.parse(pelvicFloorExerciseParams);
		} catch (error) {
			logParseError('pelvicFloorExercise', error, whoops);
			parseError = true;
		}
	}

	// Only return null if both params are undefined or both failed to parse
	if (
		!pelvicFloorExerciseParams ||
		(parseError && Object.keys(params).length === 0)
	) {
		return null;
	}

	return params;
};

export const validatePelvicFloorParams = (
	whoops: (_cher: Cher | null, _options?: SetWhoopsOptions) => void,
	pelvicFloorExerciseParams?: Record<string, any>,
) => {
	if (
		pelvicFloorExerciseParams &&
		!isPelvicFloorExerciseParams(pelvicFloorExerciseParams)
	) {
		logParseError(
			'pelvicFloorExerciseParams',
			new Error('Invalid pelvic floor exercise params'),
			whoops,
		);
		return false;
	}
	// parameters can be undefined/null, and that's fine
	return true;
};
