import { BiPoll } from '@react-icons/all-files/bi/BiPoll';
import { defineType } from '@sanity/types';

import { Document } from './_types';
import { ksuidIdField } from './_utils';
import { PollOptionType } from './PollOption';

export type PollType = Document & {
	_type: 'Poll';
	id: string;
	name: string;
	preventResubmission: boolean;
	maxChoices: number;
	randomizeOptions: boolean;
	randomizationFixedTopCount: number;
	randomizationFixedBottomCount: number;
	options: PollOptionType[];
	isSlider?: boolean;
	maximumPollOptionValue?: number;
	minimumPollOptionValue?: number;
};

export default defineType({
	name: 'Poll',
	type: 'document',
	icon: BiPoll,
	fields: [
		ksuidIdField('poll', true),
		{
			name: 'name',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'dataClassification',
			type: 'string',
			validation: (r) => r.required(),
			initialValue: 'special_category',
			readOnly: (c) =>
				(c.document as PollType | undefined)?.std_published ?? false,
			description:
				'Please look through the "Pseudonyms" page on Notion before changing this field',
			options: {
				list: [
					{ title: 'Special category', value: 'special_category' },
					{ title: 'Standard personal data', value: 'standard_personal' },
				],
			},
		},
		{
			name: 'preventResubmission',
			type: 'boolean',
			validation: (r) => r.required(),
			initialValue: false,
		},
		{
			name: 'isSlider',
			type: 'boolean',
			validation: (r) => r.required(),
			initialValue: false,
			description: 'Display poll options as a slider',
		},
		{
			name: 'minimumPollOptionValue',
			type: 'number',
			validation: (r) => r.integer().positive(),
			initialValue: 0,
			hidden: ({ parent }: { parent: PollType }) => !parent.isSlider,
			description:
				'Minimum value that can be selected in the slider (must match an existing poll option)',
		},
		{
			name: 'maximumPollOptionValue',
			type: 'number',
			validation: (r) => r.integer().positive(),
			initialValue: 0,
			hidden: ({ parent }: { parent: PollType }) => !parent.isSlider,
			description:
				'Maximum value that can be selected in the slider (must match an existing poll option)',
		},
		{
			name: 'maxChoices',
			type: 'number',
			validation: (r) =>
				r
					.required()
					.integer()
					.positive()
					.custom((value: number, context) => {
						const doc = context?.document as PollType;
						const optionsLength = doc?.options?.length ?? 0;

						if (doc?.isSlider && value !== 1) {
							return 'Slider polls must have maxChoices set to 1';
						}

						if (value > optionsLength) {
							return 'Max choices cannot exceed the number of poll options';
						}
						return true;
					}),
			initialValue: 1,
			readOnly: ({ parent }: { parent: PollType }) => !!parent.isSlider,
		},
		{
			name: 'randomizeOptions',
			type: 'boolean',
			validation: (r) => r.required(),
			initialValue: false,
			hidden: ({ parent }: { parent: PollType }) => !!parent.isSlider,
		},
		{
			name: 'randomizationFixedTopCount',
			type: 'number',
			description:
				'Number of options to keep at the top of the list when randomizing',
			validation: (r) => r.required().integer().positive(),
			initialValue: 0,
			hidden: ({ parent }: { parent: PollType }) =>
				parent.isSlider || !parent.randomizeOptions,
		},
		{
			name: 'randomizationFixedBottomCount',
			type: 'number',
			description:
				'Number of options to keep at the bottom of the list when randomizing',
			validation: (r) => r.required().integer().positive(),
			initialValue: 0,
			hidden: ({ parent }: { parent: PollType }) =>
				parent.isSlider || !parent.randomizeOptions,
		},
		{
			name: 'options',
			type: 'array',
			of: [{ type: 'PollOption' }],
			validation: (r) => r.required(),
		},
	],
	validation: (r) =>
		r.custom<Required<PollType>>((value) => {
			if (value?.isSlider) {
				if (value.maxChoices !== 1) {
					return 'Slider polls must have maxChoices set to 1';
				}

				if (value.randomizeOptions) {
					return 'Slider polls cannot have randomized options';
				}

				const hasInvalidKeys = value.options?.some((option) => {
					const num = Number(option.key);
					return isNaN(num) || !Number.isInteger(num);
				});

				if (hasInvalidKeys) {
					return 'Slider poll options must have numeric keys';
				}

				const optionValues = value.options.map((option) => Number(option.key));

				if (
					value.minimumPollOptionValue !== undefined &&
					!optionValues.includes(value.minimumPollOptionValue)
				) {
					return 'Minimum value must match one of the poll option values';
				}

				if (
					value.maximumPollOptionValue !== undefined &&
					!optionValues.includes(value.maximumPollOptionValue)
				) {
					return 'Maximum value must match one of the poll option values';
				}

				if (
					value.minimumPollOptionValue !== undefined &&
					value.maximumPollOptionValue !== undefined &&
					value.minimumPollOptionValue >= value.maximumPollOptionValue
				) {
					return 'Maximum value must be greater than minimum value';
				}
			}

			const fixedCount =
				(value?.randomizationFixedTopCount ?? 0) +
				(value?.randomizationFixedBottomCount ?? 0);
			const randomizedCount = (value?.options?.length ?? 0) - fixedCount;
			switch (true) {
				case Boolean(value?.randomizeOptions && randomizedCount >= 2):
				case Boolean(!value?.randomizeOptions && fixedCount === 0):
					return true;
				default:
					return 'Invalid randomization configuration';
			}
		}),
	preview: {
		select: {
			title: 'name',
			subtitle: 'id',
		},
	},
});
