import { ProgramActivityType } from '@wearemojo/sanity-schema';
import { useMemo } from 'react';

import { evaluateBoolean } from '../utils/expressionEvaluation';
import useEvaluationArgs from './useEvaluationArgs';

type Props = {
	activity?: ProgramActivityType;
	contextVariables?: Record<string, unknown>;
};

/**
 * Custom hook to resolve the appropriate variant of an activity based on various conditions.
 * It utilizes the provided activity and context variables to determine which variant to use.
 * The selection is based on evaluating conditions defined in each variant against the current
 * application state and user responses.
 *
 * @param {Props} props - The properties including the activity and context variables.
 * @returns {ProgramActivityType | undefined} The resolved activity variant or undefined if no activity is provided.
 */

const useResolveActivityVariants = ({ activity, contextVariables }: Props) => {
	const { isLoadingArgs, getEvaluationArgs } = useEvaluationArgs();

	const variant = useMemo(() => {
		if (!activity) return;

		const selectedVariant = activity?.variants?.find((v) =>
			v.condition.every((condition) =>
				evaluateBoolean(condition, getEvaluationArgs({ contextVariables })),
			),
		);

		return selectedVariant || activity.defaultVariant;
	}, [activity, contextVariables, getEvaluationArgs]);

	return {
		isFetchingPolls: isLoadingArgs,
		variant,
	};
};

export default useResolveActivityVariants;
