import React, { ReactNode } from 'react';
import { useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import KeyboardAvoidingView from './KeyboardAvoidingView';

type KeyboardAvoidingViewWrapperProps = {
	children: ReactNode;

	/** The behavior to pass to the KeyboardAvoidingView, requires some trial and error depending on the layout/devices used. */
	behavior?: 'padding' | 'height' | 'position';

	/** Whether to use the maxHeight (true) or use the 100% of the height (false) */
	shouldEnableMaxHeight?: boolean;

	/** Whether to include padding bottom */
	includeSafeAreaPaddingBottom?: boolean;
};

const KeyboardAvoidingViewWrapper = ({
	children,
	behavior = 'padding',
	shouldEnableMaxHeight = false,
	includeSafeAreaPaddingBottom = true,
}: KeyboardAvoidingViewWrapperProps) => {
	const { styles } = useStyles(stylesheet);
	const { height: screenHeight } = useWindowDimensions();
	const insets = useSafeAreaInsets();
	const maxHeight = shouldEnableMaxHeight ? screenHeight : undefined;
	return (
		<KeyboardAvoidingView
			behavior={behavior}
			style={[
				styles.keyboard,
				{ maxHeight },
				includeSafeAreaPaddingBottom && { paddingBottom: insets.bottom },
			]}
		>
			{children}
		</KeyboardAvoidingView>
	);
};

const stylesheet = createStyleSheet(() => ({
	keyboard: {
		flex: 1,
	},
}));

export default KeyboardAvoidingViewWrapper;
