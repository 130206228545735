import { PortableTextComponentProps } from '@portabletext/react';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { borderRadius } from '@wearemojo/ui-constants';
import { StyleSheet } from 'react-native';

import Image from '../Image';
import { useCMSGlobalContext } from '../utils/cms';
import CMSBlock from './CMSBlock';

type CMSImageProps = PortableTextComponentProps<SanityImageSource>;
const CMSImage = ({ value }: CMSImageProps) => {
	const { imgAttr } = useCMSGlobalContext();
	const {
		uri,
		dimensions: { aspectRatio, width: maxWidth } = {},
		format,
		altText,
	} = imgAttr(value);
	return (
		<CMSBlock style={styles.root} textAlign="auto">
			<Image
				source={{ uri }}
				aspectRatio={aspectRatio}
				style={{ maxWidth }}
				format={format}
				alt={altText === null ? '' : altText}
				resizeMode="contain"
			/>
		</CMSBlock>
	);
};

const styles = StyleSheet.create({
	root: {
		borderRadius,
		overflow: 'hidden',
	},
});

export default CMSImage;
