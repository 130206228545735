import CheckSquareIcon from '@wearemojo/icons/Bold/EssentionalUI/CheckSquareIcon';
import { BrandColor } from '@wearemojo/ui-constants';
import { ReactNode } from 'react';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BackgroundView from './BackgroundView';
import Text from './Text';

type Props = {
	pointsPosition: 'above_video' | 'below_video';
	video: ReactNode;
	title: string;
	points: string[];
};

const VideoKeyPoints = ({
	pointsPosition,
	video,
	title,
	points = [],
}: Props) => {
	const { styles } = useStyles(stylesheet);

	const pointsEl = (
		<View style={styles.container}>
			{!!title && <Text variant="heading_xs">{title}</Text>}
			{points.map((point, i) => (
				<View style={styles.point} key={i}>
					<View>
						<CheckSquareIcon fill={BrandColor.primary_purple_lighter} />
					</View>
					<Text style={styles.pointText}>{point}</Text>
				</View>
			))}
		</View>
	);
	return (
		<BackgroundView background="background_level_1" style={styles.root}>
			{pointsPosition === 'above_video' && pointsEl}
			<View style={styles.video}>{video}</View>
			{pointsPosition === 'below_video' && pointsEl}
		</BackgroundView>
	);
};

const stylesheet = createStyleSheet(({ spacing, radius, colors }) => ({
	root: {
		borderRadius: radius.md,
		overflow: 'hidden',
	},
	video: {
		shadowColor: colors.content_on_fill,
		shadowOffset: { width: 0, height: 0 },
		shadowOpacity: 0.5,
		shadowRadius: radius.xl3,
		borderRadius: radius.md,
		elevation: 5,
	},
	container: {
		marginTop: spacing.xs2,
		marginBottom: spacing.sm,
		padding: spacing.lg,
		gap: spacing.xl,
	},
	point: {
		flexDirection: 'row',
		alignItems: 'center',
		gap: spacing.sm,
	},
	pointText: {
		flex: 1,
	},
}));

export default VideoKeyPoints;
