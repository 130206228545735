type Options = { itemCount: number };
// This function trims a given array to a specified number
// It returns the last N number of arrays based on {itemCount}
const trimToNItems = <T>(arr: T[], { itemCount }: Options): T[] => {
	const length = arr.length;
	const remainder = length % itemCount;

	if (length <= itemCount) return arr;
	if (remainder === 0) return arr.slice(-itemCount);
	return arr.slice(remainder * -1);
};

// This function is useful to filter undefined/null elements from an array,
// and correctly narrow the type
export function isNonNullable<T>(x: T): x is NonNullable<T> {
	return x != null;
}

export default { trimToNItems, isNonNullable };
