import { Temporal } from '@js-temporal/polyfill';
import { ActivityCompletedItem } from '@wearemojo/api-client/src/services/learntracking';
import { useMemo } from 'react';

import useCurrentTrackingDate from '../store/api/hooks/useCurrentTrackingDate';
import useUserActivity from '../store/api/hooks/useUserActivity';

type ActivityCoinEarningStatus = {
	canEarnCoins: boolean;
	hasEarnedCoinsToday: boolean;
};

const useActivityCoinEarningStatus = (
	activityId: string,
): ActivityCoinEarningStatus => {
	const { userActivity } = useUserActivity();
	const currentTrackingDate = useCurrentTrackingDate();

	return useMemo(() => {
		if (!userActivity || !currentTrackingDate || !activityId) {
			return { canEarnCoins: false, hasEarnedCoinsToday: false };
		}

		const todayActivities = userActivity.filter(
			(activity): activity is ActivityCompletedItem =>
				activity.params.activityId === activityId &&
				activity.type === 'activity_completed' &&
				Temporal.PlainDate.from(activity.trackingDate).equals(
					currentTrackingDate,
				),
		);

		const canEarnCoins = todayActivities.length <= 1;
		const hasEarnedCoinsToday = todayActivities.some(
			(activity) =>
				typeof activity.params.netCoins === 'number' &&
				activity?.params?.netCoins > 0,
		);

		return { canEarnCoins, hasEarnedCoinsToday };
	}, [userActivity, currentTrackingDate, activityId]);
};

export default useActivityCoinEarningStatus;
