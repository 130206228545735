import { Spacing, themeColors, UITheme } from '@wearemojo/ui-constants';
import { PropsWithChildren } from 'react';
import { ImageSourcePropType, Platform, StyleSheet, View } from 'react-native';
import Animated from 'react-native-reanimated';
import Svg, { Path } from 'react-native-svg';

import useSequenceAnimation from './hooks/useSequenceAnimation';
import useUIContext from './hooks/useUIContext';
import useUITheme from './hooks/useUITheme';
import Image from './Image';

type FlairSizeType = 'big' | 'medium' | 'small';

type Props = {
	icon?: 'tick';
	size?: FlairSizeType;
	animated?: boolean;
	flairColor?: string;
};

const flairScaleFactor: Record<FlairSizeType, number> = {
	big: 1,
	medium: 0.625,
	small: 0.35,
};

type FlairProps = PropsWithChildren & Props;

const FULL_SIZE = 240;
const ICON_SIZE = 54;
const SEGMENT_GAP = 14;

const tuple = <T extends unknown[]>(...args: T): T => args;

type Indices<T extends { length: number }> = Exclude<
	Partial<T>['length'],
	T['length']
>;

const segmentAnimations = tuple(
	// Based on 30 fps
	{
		scale: [
			{ frame: 12, value: 0.95 },
			{ frame: 19, value: 1.35 },
			{ frame: 23, value: 1.15 },
			{ frame: 28, value: 1 },
		],
		opacity: [
			{ frame: 12, value: 0 },
			{ frame: 25, value: 0.2 },
		],
	},
	{
		scale: [
			{ frame: 11, value: 0 },
			{ frame: 15, value: 0.85 },
			{ frame: 35, value: 1 },
		],
		opacity: [
			{ frame: 15, value: 0 },
			{ frame: 24, value: 0.4 },
		],
	},
	{
		scale: [
			{ frame: 14, value: 0.7 },
			{ frame: 30, value: 1 },
		],
		opacity: [
			{ frame: 14, value: 0 },
			{ frame: 28, value: 0.6 },
		],
	},
	{
		scale: [
			{ frame: 21, value: 0.65 },
			{ frame: 30, value: 1 },
		],
		opacity: [
			{ frame: 21, value: 0 },
			{ frame: 28, value: 1 },
		],
	},
);

const Flair = ({
	children,
	icon,
	size = 'big',
	animated = Platform.OS !== 'android', // @TODO: Buggy on Android, disabled by default for now
	flairColor,
}: FlairProps) => {
	const scale = flairScaleFactor[size];
	const scaledSize = FULL_SIZE * scale;
	const offset = -((FULL_SIZE - scaledSize) / 2);
	const transform = [{ translateX: offset }, { translateY: offset }, { scale }];
	const shouldScale = scale !== 1;
	return (
		<View style={styles.root}>
			<View
				style={[
					styles.container,
					shouldScale && { width: scaledSize, height: scaledSize },
				]}
			>
				<View style={[styles.flair, shouldScale && { transform }]}>
					<Segment color={flairColor} animated={animated} index={0} />
					<Segment color={flairColor} animated={animated} index={1} />
					<Segment color={flairColor} animated={animated} index={2} />
					<Segment color={flairColor} animated={animated} index={3}>
						{children}
					</Segment>
					{icon === 'tick' && <Tick animated={animated} />}
				</View>
			</View>
		</View>
	);
};

const FlairImage = ({ source }: { source: ImageSourcePropType }) => {
	return <Image source={source} style={styles.image} alt="flair" />;
};

const Tick = ({ animated }: { animated?: boolean }) => {
	const iconColor = useUITheme().background_primary;
	const { animatedStyles, staticStyles } = useSequenceAnimation(
		segmentAnimations[3],
	);

	return (
		<Animated.View
			// @ts-ignore @TODO understand why type is incorrect and fix it
			style={[styles.icon, animated ? animatedStyles : staticStyles]}
		>
			<TickIcon fill={iconColor} />
		</Animated.View>
	);
};

const Segment = ({
	index,
	children,
	animated,
	color,
}: {
	index: Indices<typeof segmentAnimations>;
	children?: React.ReactNode;
	animated?: boolean;
	color?: string;
}) => {
	const offset = index * SEGMENT_GAP;
	const size = FULL_SIZE - index * SEGMENT_GAP * 2;
	const { theme } = useUIContext();
	const { animatedStyles, staticStyles } = useSequenceAnimation(
		segmentAnimations[index],
	);

	const defaultBackgroundColor = themeColors[theme].fill_highlight;
	const backgroundColor = color ?? defaultBackgroundColor;

	return (
		<Animated.View
			style={[
				styles.segment,
				{ width: size, height: size },
				{ top: offset, left: offset },
				{ backgroundColor },
				// @ts-ignore @TODO understand why type is incorrect and fix it
				animated ? animatedStyles : staticStyles,
			]}
		>
			{children}
		</Animated.View>
	);
};

const TickIcon = ({ fill: _fill }: { fill?: string }) => {
	const defaultFill = useUITheme().border_primary;
	const fill = _fill ?? defaultFill;
	return (
		<Svg width={24} height={18} fill="none">
			<Path
				d="m3 9 6 6L21 3"
				stroke={fill}
				strokeWidth={6}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</Svg>
	);
};

Flair.Image = FlairImage;

const styles = StyleSheet.create({
	root: {
		alignItems: 'center',
	},
	container: {
		width: FULL_SIZE,
		height: FULL_SIZE,
	},
	flair: {
		width: FULL_SIZE,
		height: FULL_SIZE,
		position: 'absolute',
		top: 0,
		left: 0,
	},
	segment: {
		position: 'absolute',
		borderRadius: FULL_SIZE / 2,
		overflow: 'hidden',
	},
	image: {
		...StyleSheet.absoluteFillObject,
		/*
			Parent has border radius and overflow hidden styles props, however Safari
			sometimes renders the image as a square (seems connected to window size).
			We fix this by repeating the style props here.
		*/
		borderRadius: FULL_SIZE / 2,
		overflow: 'hidden',
	},
	icon: {
		position: 'absolute',
		top: Spacing.extraSmall,
		right: Spacing.extraSmall,
		width: ICON_SIZE,
		height: ICON_SIZE,
		borderRadius: ICON_SIZE / 2,
		overflow: 'hidden',
		backgroundColor: themeColors[UITheme.dark].fill_highlight,
		padding: Spacing.large,
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default Flair;
