import { FaRobot } from '@react-icons/all-files/fa6/FaRobot';
import { defineField, defineType } from '@sanity/types';

import { Document } from './_types';
import { ksuidIdField, validateSnakeCase } from './_utils';
import { ItoLLMConfigType } from './ItoLLMConfig';

export enum ThreadMode {
	Singleton = 'singleton',
	TrackingDate = 'tracking_date',
	OneOff = 'one_off',
}

enum EntryType {
	Activities = 'activities',
	Action = 'action',
}

enum ActionType {
	Navigate = 'navigate',
	ContactSupport = 'contact_support',
	HelplineReferral = 'helpline_referral',
	ContinueFlow = 'continue_flow',
}

enum Tool {
	RecordPollResponse = 'record_poll_response',
	UpsertMemory = 'upsert_memory',
}

const llmConfigField = defineField({
	name: 'llmConfig',
	type: 'reference',
	validation: (r) => r.required(),
	to: [{ type: 'ItoLLMConfig' }],
});

const allowedEntryTypesField = defineField({
	name: 'allowedEntryTypes',
	type: 'array',
	description: 'Types of entries this configuration can generate',
	initialValue: [EntryType.Activities, EntryType.Action],
	of: [
		{
			type: 'string',
			options: {
				list: Object.values(EntryType).map((type) => ({
					title: type,
					value: type,
				})),
			},
		},
	],
	validation: (r) =>
		r.custom((entryTypes?: EntryType[]) => {
			if (!entryTypes) return true;

			if (entryTypes.some((entry) => !entry)) {
				return 'Empty entry types not allowed';
			}

			const uniqueTypes = new Set(entryTypes);
			if (entryTypes.length !== uniqueTypes.size) {
				return 'Entry types must be unique - no duplicates allowed';
			}
			return true;
		}),
});

const allowedActionTypesField = defineField({
	name: 'allowedActionTypes',
	type: 'array',
	description: 'Types of actions this configuration can trigger',
	initialValue: [
		ActionType.Navigate,
		ActionType.ContactSupport,
		ActionType.HelplineReferral,
	],
	of: [
		{
			type: 'string',
			options: {
				list: Object.values(ActionType).map((type) => ({
					title: type,
					value: type,
				})),
			},
		},
	],
	validation: (r) =>
		r.custom((actionTypes?: ActionType[]) => {
			if (!actionTypes) return true;

			if (actionTypes.some((action) => !action)) {
				return 'Empty action types not allowed';
			}

			const uniqueTypes = new Set(actionTypes);
			if (actionTypes.length !== uniqueTypes.size) {
				return 'Action types must be unique - no duplicates allowed';
			}
			return true;
		}),
});

const allowedToolsField = defineField({
	name: 'allowedTools',
	type: 'array',
	description: 'The tools this agent is allowed to call',
	initialValue: [Tool.UpsertMemory],
	of: [
		{
			type: 'string',
			options: {
				list: Object.values(Tool).map((t) => ({
					title: t,
					value: t,
				})),
			},
		},
	],
});

const toolFields = [
	defineField({
		name: 'allowedPolls',
		type: 'array',
		description: 'The polls this agent is allowed to record responses for',
		of: [
			{
				type: 'reference',
				to: [{ type: 'Poll' }],
			},
		],
	}),
];

// TODO: legacy, remove
enum Model {
	OpenAIGPT4o = 'openai/gpt-4o',
	OpenAIGPT4oMini = 'openai/gpt-4o-mini',
}

// TODO: legacy, remove
const promptNameField = defineField({
	name: 'promptName',
	type: 'string',
	description:
		'The name of the prompt to use for this agent (e.g., "live-homescreen-chat" for chat agent, "live-homescreen-safety" for safety agent)',
	validation: (r) => r.required(),
});

// TODO: legacy, remove
const modelField = defineField({
	name: 'model',
	type: 'string',
	description: 'The LLM model for this agent',
	options: {
		list: Object.values(Model).map((model) => ({
			title: model,
			value: model,
		})),
	},
	validation: (r) => r.required(),
});

export type ItoAssistantConfigType = Document & {
	_type: 'ItoAssistantConfig';
	id: string;
	graph: string;
	threadConfig: {
		purpose: string;
		mode: `${ThreadMode}`;
	};
	nodeConfig: {
		chat: {
			llmConfig: ItoLLMConfigType;
			allowSuggestedReplies: boolean;
			allowedEntryTypes?: `${EntryType}`[];
			allowedActionTypes?: `${ActionType}`[];
			allowedTools?: `${Tool}`[];
		};
		safety: {
			llmConfig: ItoLLMConfigType;
		};
		threadSummary?: {
			llmConfig: ItoLLMConfigType;
		};
		tools?: {
			allowedPolls?: { id: string }[];
		};
	};

	// TODO: legacy, remove
	agentConfig: {
		chat: {
			promptName: string;
			model: `${Model}`;
			allowedTools?: `${Tool}`[];
			toolOptions?: {
				allowedPolls?: { id: string }[];
			};
			allowSuggestedReplies: boolean;
		};
		summary?: {
			promptName: string;
			model: `${Model}`;
		};
		safety: {
			promptName: string;
			model: `${Model}`;
		};
	};
	allowedEntryTypes?: `${EntryType}`[];
	allowedActionTypes?: `${ActionType}`[];
};

export default defineType({
	name: 'ItoAssistantConfig',
	type: 'document',
	icon: FaRobot,
	fields: [
		ksuidIdField('itoconf', true),
		{
			name: 'graph',
			type: 'string',
			description: 'The graph to use for this configuration',
			validation: (r) => r.required(),
			initialValue: 'example',
			readOnly: true,
		},
		{
			name: 'threadConfig',
			type: 'object',
			description: 'Configuration for thread management',
			validation: (r) => r.required(),
			initialValue: {
				purpose: 'home_screen',
				mode: ThreadMode.Singleton,
			},
			fields: [
				{
					name: 'purpose',
					type: 'string',
					description:
						'The purpose of the thread (used in key generation) (e.g. "home_screen")',
					validation: (r) => validateSnakeCase(r),
				},
				{
					name: 'mode',
					type: 'string',
					description: 'How thread keys should be generated',
					validation: (r) => r.required(),
					options: {
						list: Object.values(ThreadMode).map((mode) => ({
							title: mode,
							value: mode,
						})),
					},
				},
			],
		},
		{
			name: 'nodeConfig',
			type: 'object',
			description: 'Configuration for individual nodes',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'chat',
					type: 'object',
					validation: (r) => r.required(),
					fields: [
						llmConfigField,
						{
							name: 'allowSuggestedReplies',
							type: 'boolean',
							description: 'Whether this configuration can suggest replies',
							validation: (r) => r.required(),
						},
						allowedEntryTypesField,
						allowedActionTypesField,
						allowedToolsField,
					],
				},
				{
					name: 'safety',
					type: 'object',
					validation: (r) => r.required(),
					fields: [llmConfigField],
				},
				{
					name: 'threadSummary',
					type: 'object',
					description: 'Leave empty to disable in-thread summarization',
					fields: [llmConfigField],
				},
				{
					name: 'tools',
					type: 'object',
					fields: toolFields,
				},
			],
		},

		// TODO: remove, legacy
		{
			name: 'agentConfig',
			type: 'object',
			fields: [
				{
					name: 'chat',
					type: 'object',
					fields: [
						promptNameField,
						modelField,
						allowedToolsField,
						defineField({
							name: 'toolOptions',
							type: 'object',
							description: 'Options to configure the available tools',
							fields: toolFields,
						}),
						{
							name: 'allowSuggestedReplies',
							type: 'boolean',
							description: 'Whether this configuration can suggest replies',
							validation: (r) => r.required(),
						},
					],
				},
				{
					name: 'summary',
					type: 'object',
					description:
						'Leave both fields empty to disable in-chat summarization',
					fields: [promptNameField, modelField],
				},
				{
					name: 'safety',
					type: 'object',
					fields: [promptNameField, modelField],
				},
			],
		},
		allowedEntryTypesField,
		allowedActionTypesField,
	],
	preview: {
		select: {
			id: 'id',
			graph: 'graph',
			purpose: 'threadConfig.purpose',
		},
		prepare({ id, graph, purpose }) {
			return {
				title: purpose,
				subtitle: `${graph} (${id})`,
			};
		},
	},
});
