import { InlineContentType } from '@wearemojo/sanity-schema';
import { ContentVariables, InlineContent } from '@wearemojo/ui-components';
import { useCallback, useEffect, useMemo, useState } from 'react';

import NavigatorKey from '../../navigation/NavigatorKey';
import ScreenKey from '../../navigation/ScreenKey';
import useGetChallenges from '../../store/api/hooks/useGetChallenges';
import {
	addNearCompletionChallenge,
	removeChallenge,
	selectNearCompletionChallenge,
} from '../../store/challengeProgress';
import useAppDispatch from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import useCoinImage from '../useCoinImage';
import useNavigationRedirect from '../useNavigationRedirect';
import { useChallengeById } from './useChallengeById';

type ChallengeProgressBottomSheetProps = {
	visible: boolean;
	title: string;
	subtitle: string | InlineContentType;
	progress: number;
	total: number;
	ctaTitle: string;
	supportLabel: InlineContent | string;
	onClose: () => void;
	onCtaPress: () => void;
	contentVariables: ContentVariables;
	chipContent?: InlineContent;
	imageSource?: string;
};

/**
 * Hook to manage a challenge progress bottom sheet that appears after completing challenge activities.
 *
 * @param activityId - The ID of the activity to check if it's part of a challenge
 * @returns {Object} An object containing:
 *   - isChallengeActivity: Whether the activity is part of a challenge
 *   - bottomSheetProps: Props for rendering the bottom sheet UI
 *   - showChallengeProgress: Function to show the bottom sheet
 *   - dismissChallengeProgressAndGoHome: Function to hide the bottom sheet and redirect to home
 *	 - isChallengeComplete: Boolean indicating if challenge is completed
 */
type ChallengeProgressState = {
	isLoading: boolean;
	error?: Error;
	isChallengeActivity: boolean;
	bottomSheetProps: ChallengeProgressBottomSheetProps | null;
};

export const useChallengeProgressBottomSheet = (activityId: string) => {
	const [isVisible, setIsVisible] = useState(false);
	const { imageSource } = useCoinImage();
	const dispatch = useAppDispatch();

	const { data: challengesData, isLoading, error } = useGetChallenges();
	const redirectToHome = useNavigationRedirect(NavigatorKey.MainNavigator, {
		screen: ScreenKey.Home,
	});

	const challengeActivityData = useMemo(() => {
		if (!activityId || !challengesData?.acceptedChallenges) {
			return { isChallengeActivity: false, challengeId: undefined };
		}

		const matchingChallenge = challengesData.acceptedChallenges.find(
			(challenge) => challenge.contributingActivityIds?.includes(activityId),
		);

		return {
			isChallengeActivity: Boolean(matchingChallenge),
			challengeId: matchingChallenge?.id,
		};
	}, [activityId, challengesData?.acceptedChallenges]);

	const { challengeId } = challengeActivityData;
	const isCompleteChallengeInReduxStore = useAppSelector(
		selectNearCompletionChallenge(challengeId ?? ''),
	);
	const challengeDetails = useChallengeById(challengeId ?? '');
	const challenge = challengeDetails?.challenge;
	const isComplete =
		challenge?.completionsDone === challenge?.completionsRequired;

	const isAboutToComplete =
		challenge?.completionsRequired &&
		challenge?.completionsDone === challenge?.completionsRequired - 1;

	useEffect(() => {
		if (challengeId && isAboutToComplete) {
			dispatch(addNearCompletionChallenge(challengeId));
		}
	}, [challengeId, isAboutToComplete, dispatch]);

	const showChallengeProgress = useCallback(async () => {
		if (!challengeId) {
			redirectToHome();
			return;
		}
		setIsVisible(true);
		if (isCompleteChallengeInReduxStore) {
			dispatch(removeChallenge(challengeId));
		}
	}, [challengeId, redirectToHome, dispatch, isCompleteChallengeInReduxStore]);

	const dismissChallengeProgressAndGoHome = useCallback(() => {
		setIsVisible(false);
		redirectToHome();
	}, [redirectToHome]);

	const state = useMemo<ChallengeProgressState>(() => {
		if (isLoading || challengeDetails.isLoading) {
			return {
				isLoading: true,
				isChallengeActivity: false,
				bottomSheetProps: null,
			};
		}

		if (error || challengeDetails.error) {
			return {
				isLoading: false,
				error: error || challengeDetails.error,
				isChallengeActivity: false,
				bottomSheetProps: null,
			};
		}

		const progressPanel = challenge?.progressPanel;

		if (
			!challenge ||
			!challengeActivityData.isChallengeActivity ||
			!progressPanel
		) {
			return {
				isLoading: false,
				isChallengeActivity: false,
				bottomSheetProps: null,
			};
		}

		return {
			isLoading: false,
			isChallengeActivity: true,
			bottomSheetProps: {
				visible: isVisible,
				title: isComplete
					? progressPanel.completedTitle
					: progressPanel.madeProgressTitle,
				subtitle: isComplete
					? progressPanel.completedSubtitle
					: progressPanel.madeProgressSubtitle,
				progress: challenge.completionsDone ?? 0,
				total: challenge.completionsRequired ?? 0,
				ctaTitle: progressPanel.ctaTitle,
				supportLabel: progressPanel.challengeDescription ?? '',
				onClose: dismissChallengeProgressAndGoHome,
				onCtaPress: dismissChallengeProgressAndGoHome,
				contentVariables: {
					completions_required: challenge.completionsRequired,
					coins_number: challenge.awardCoins,
				},
				chipContent: progressPanel.chipContent,
				imageSource,
			},
		};
	}, [
		isLoading,
		error,
		challengeDetails,
		challengeActivityData.isChallengeActivity,
		isVisible,
		imageSource,
		dismissChallengeProgressAndGoHome,
		isComplete,
		challenge,
	]);

	return {
		...state,
		showChallengeProgress,
		dismissChallengeProgressAndGoHome,
		isChallengeComplete: isComplete && !isCompleteChallengeInReduxStore,
	};
};
