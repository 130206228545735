import { MultipleContentCardBlockType } from '@wearemojo/sanity-schema';
import { BrandColor } from '@wearemojo/ui-constants';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import Image from '../Image';
import Text from '../Text';
import { useCMSGlobalContext } from '../utils/cms';

const MultipleContentCard = ({
	options,
	withBackground,
}: Omit<MultipleContentCardBlockType, '_type'>) => {
	const { styles } = useStyles(stylesheet, { withBackground });
	const { imgAttr } = useCMSGlobalContext();
	return (
		<View style={styles.root}>
			{options.map((option, index) => {
				const image = option.image ? imgAttr(option.image) : undefined;
				return (
					<View style={styles.card} key={index}>
						<Image
							source={{ uri: image?.uri }}
							style={styles.image}
							resizeMode="cover"
							format="svg"
						/>
						<Text.CMSContent
							value={option.content}
							variant="body_md"
							themeColor="content_neutral100"
						/>
					</View>
				);
			})}
		</View>
	);
};

const stylesheet = createStyleSheet(({ radius, spacing }) => ({
	root: {
		borderRadius: radius.md,
		padding: spacing.xl,
		backgroundColor: BrandColor.neutral_700,
		gap: spacing.md,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		variants: {
			withBackground: {
				true: {
					backgroundColor: BrandColor.neutral_700,
				},
				false: {
					backgroundColor: 'none',
				},
			},
		},
	},
	card: {
		flexDirection: 'row',
		gap: spacing.xs,
	},
	content: {
		flex: 1,
		justifyContent: 'center',
	},
	image: {
		height: 20,
		width: 20,
	},
}));

export default MultipleContentCard;
