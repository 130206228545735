import { useSanityImageAttributes } from '@wearemojo/ui-components';

import { FragmentType } from '../content/FragmentType';
import { useFragment } from './content/useFragment';

const useCoinImage = () => {
	const { image } = useFragment(FragmentType.FragmentCoin);
	const imageAttr = useSanityImageAttributes(480);
	const imageSource = imageAttr(image).uri;

	return { imageSource };
};

export default useCoinImage;
