import { PortableTextComponentProps } from '@portabletext/react';
import { VideoKeyPointsType } from '@wearemojo/sanity-schema';

import useUIContext from '../hooks/useUIContext';
import { useCMSGlobalContext } from '../utils/cms';
import Video from '../Video';
import VideoKeyPoints from '../VideoKeyPoints';
import CMSBlock from './CMSBlock';

type CMSVideoKeyPointsProps = PortableTextComponentProps<VideoKeyPointsType>;
const CMSVideoKeyPoints = ({ value }: CMSVideoKeyPointsProps) => {
	const { isIos } = useUIContext();
	const { imgAttr } = useCMSGlobalContext();
	const { pointsPosition, points, title } = value;
	const { name, cloudflareVideoId, poster, useNativePlayerOnMobile } =
		value.video;

	const posterUri = poster ? imgAttr(poster).uri : undefined;
	const useNativePlayer = isIos && !!useNativePlayerOnMobile;
	const video = (
		<Video
			useNativePlayer={useNativePlayer}
			config={{
				type: 'cloudflare',
				videoId: cloudflareVideoId,
				posterUri,
			}}
			eventFields={{
				name,
				cloudflare_video_id: cloudflareVideoId,
			}}
		/>
	);

	return (
		<CMSBlock textAlign="auto">
			<VideoKeyPoints
				pointsPosition={pointsPosition ?? 'below_video'}
				video={video}
				title={title}
				points={points}
			/>
		</CMSBlock>
	);
};

export default CMSVideoKeyPoints;
