import { BrandColor, UIThemeColors } from '@wearemojo/ui-constants';
import { Image, ImageSource } from 'expo-image';
import { ComponentType } from 'react';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import useUITheme from './hooks/useUITheme';
import PressableOpacity from './PressableOpacity';
import Text from './Text';
import { LinkProviderType } from './utils/links';
import { ContentVariables, InlineContent, MojoIcon } from './utils/types';

// TODO: possibly replace `Tag` with this component
// TODO: change BrandColor to use tokens

type ChipProps = {
	background: keyof typeof BrandColor;
	textColor?: keyof UIThemeColors;
	contentVariables?: ContentVariables;
	title: InlineContent;
};

const Chip = ({
	background,
	textColor,
	contentVariables,
	title,
}: ChipProps) => {
	const { styles } = useStyles(stylesheet, {
		background,
	});

	return (
		<View style={styles.container}>
			<Text.CMSContent
				variant="heading_xxs_upper"
				value={title}
				themeColor={textColor}
				contentVariables={contentVariables}
			/>
		</View>
	);
};

type WithImageProps = ChipProps & {
	imageSource: ImageSource;
};

const WithImage = ({
	background,
	textColor,
	contentVariables,
	title,
	imageSource,
}: WithImageProps) => {
	const { styles } = useStyles(stylesheet, {
		background,
		variant: 'withImage',
	});
	return (
		<View style={styles.container}>
			<Image source={imageSource} style={styles.image} />
			<Text.CMSContent
				variant="heading_xxs_upper"
				value={title}
				themeColor={textColor}
				contentVariables={contentVariables}
			/>
		</View>
	);
};

type WithLinkProviderProps = ChipProps & {
	linkProvider: LinkProviderType;
	icon?: ComponentType<MojoIcon>;
	imageSource?: ImageSource;
	isActive?: boolean;
};

const WithLinkProvider = ({
	background,
	textColor,
	contentVariables,
	title,
	linkProvider: LinkProvider,
	imageSource,
	icon: Icon,
	isActive,
}: WithLinkProviderProps) => {
	const uiTheme = useUITheme();
	const { styles, theme } = useStyles(stylesheet, {
		background,
		variant: 'withImage',
	});

	return (
		<LinkProvider>
			<PressableOpacity style={styles.container}>
				{!imageSource && Icon ? (
					<View style={[styles.iconContainer]}>
						<Icon
							size={theme.iconSize.xs}
							color={
								isActive ? uiTheme.fill_highlight : uiTheme.content_neutral
							}
						/>
					</View>
				) : (
					<Image source={imageSource} style={styles.image} />
				)}
				<Text.CMSContent
					variant="heading_xxs_upper"
					value={title}
					themeColor={textColor}
					contentVariables={contentVariables}
				/>
			</PressableOpacity>
		</LinkProvider>
	);
};

const brandColorKeys = Object.keys(BrandColor) as (keyof typeof BrandColor)[];
const chipBackgroundStyles = brandColorKeys.reduce(
	(acc, key) => {
		acc[key] = {
			backgroundColor: BrandColor[key],
		};
		return acc;
	},
	{} as Record<keyof typeof BrandColor, { backgroundColor: string }>,
);

const stylesheet = createStyleSheet(({ spacing, radius, iconSize }) => ({
	container: {
		paddingVertical: spacing.xs,
		borderRadius: radius.full,
		alignSelf: 'flex-start',
		variants: {
			background: chipBackgroundStyles,
			variant: {
				default: {
					paddingHorizontal: spacing.lg,
				},
				withImage: {
					gap: spacing.xs,
					paddingHorizontal: spacing.xs,
					flexDirection: 'row',
					alignItems: 'center',
				},
			},
		},
	},
	image: {
		width: 14.5,
		height: 16,
	},
	iconContainer: {
		width: iconSize.xs,
		height: iconSize.xs,
	},
}));

Chip.WithImage = WithImage;
Chip.WithLinkProvider = WithLinkProvider;

export default Chip;
