import { defineType } from '@sanity/types';

import { Document, Image } from '../_types';
import { InlineContentWrapperType } from '../block/InlineContentWrapper';

export type FragmentEarnedCoinsType = Document & {
	_type: 'FragmentEarnedCoins';
	image: Image;
	title: InlineContentWrapperType;
	description: InlineContentWrapperType;
	ctaText: string;
};

export default defineType({
	name: 'FragmentEarnedCoins',
	type: 'document',
	fields: [
		{
			name: 'image',
			type: 'image',
		},
		{
			name: 'title',
			type: 'reference',
			to: [{ type: 'InlineContentWrapper' }],
			validation: (r) => r.required(),
		},
		{
			name: 'description',
			type: 'reference',
			to: [{ type: 'InlineContentWrapper' }],
			validation: (r) => r.required(),
		},
	],
});
