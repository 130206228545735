import { defineType } from '@sanity/types';

export type PollOptionType = {
	_type: 'PollOption';
	text: string;
	key: string;
	isMinimumValue?: boolean;
	isMaximumValue?: boolean;
};

export default defineType({
	name: 'PollOption',
	type: 'object',
	fields: [
		{
			name: 'text',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'key',
			type: 'string',
			validation: (r) =>
				r.required().custom((value: string, context) => {
					const parentDoc = context.document;
					const isSlider = parentDoc?.isSlider;

					if (isSlider) {
						const num = Number(value);
						if (isNaN(num) || !Number.isInteger(num)) {
							return 'Key must be a valid integer number for slider polls';
						}
						return true;
					}

					return true;
				}),
		},
	],
	preview: {
		select: {
			title: 'key',
			subtitle: 'text',
		},
	},
});
