import { HiOutlineAdjustments } from '@react-icons/all-files/hi/HiOutlineAdjustments';
import { defineType } from '@sanity/types';

import { previewNamedBlockContent } from '../_utils';
import { InlineContentType } from '../block/InlineContent';
import { PollType } from '../Poll';
import { FlowBasePageType } from './baseTypes';

export type FlowPollSliderStepType = FlowBasePageType & {
	_type: 'FlowPollSliderStep';
	title: InlineContentType;
	poll: PollType;
	pollSelectionRequired: boolean;
	nextCtaText: string;
};

export default defineType({
	name: 'FlowPollSliderStep',
	type: 'document',
	icon: HiOutlineAdjustments,
	fields: [
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'poll',
			type: 'reference',
			to: [{ type: 'Poll' }],
			validation: (r) => r.required(),
		},
		{
			name: 'pollSelectionRequired',
			type: 'boolean',
			initialValue: false,
			validation: (r) => r.required(),
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required().max(30),
		},
	],
	preview: previewNamedBlockContent('title'),
});
