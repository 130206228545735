import { useCallback, useMemo } from 'react';

import { useTodayTasks } from '../store/api/hooks/useHomeTasks';
import useUserActivity from '../store/api/hooks/useUserActivity';
import { isNonNullable } from '../utils/arrayUtils';
import { useActivities } from './content/useActivities';

export const useActivityLockStatus = () => {
	const { activityById } = useActivities();
	const { data: todayTasks } = useTodayTasks();
	const { userActivity } = useUserActivity();

	const completedActivities = useMemo(() => {
		if (!userActivity) return [];
		return Array.from(
			new Set(
				userActivity
					.map((ua) => {
						if (ua.type !== 'activity_completed') return;
						const act = activityById(ua.params.activityId);
						if (act?.state === 'live') return act;
					})
					.filter(isNonNullable),
			),
		);
	}, [activityById, userActivity]);

	const isLocked = useCallback(
		(activityId: string) => {
			return (
				!completedActivities?.some((act) => act.id === activityId) &&
				!todayTasks?.items.some((task) => task.params.activityId === activityId)
			);
		},
		[completedActivities, todayTasks],
	);

	return { isLocked };
};
