import { HiOutlineDocumentText } from '@react-icons/all-files/hi/HiOutlineDocumentText';
import { defineType } from '@sanity/types';

import { ItoAssistantConfigType } from '../ItoAssistantConfig';
import { FlowBasePageType } from './baseTypes';

export type FlowItoChatStepType = FlowBasePageType & {
	_type: 'FlowItoChatStep';
	inputFieldLabel: string;
	reportMessageTitle: string;
	assistantConfig: ItoAssistantConfigType;
};

export default defineType({
	name: 'FlowItoChatStep',
	type: 'document',
	description:
		'This will not work before the user has been authenticated and the user has a pseudonym, so this should be after the user has accepted our terms',
	icon: HiOutlineDocumentText,
	fields: [
		{
			name: 'documentTitle',
			type: 'string',
			description: 'The title of the step, will not be used in the app',
			validation: (r) => r.required(),
		},
		{
			name: 'inputFieldLabel',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'reportMessageTitle',
			type: 'string',
			initialValue: 'Report',
			validation: (r) => r.required(),
		},
		{
			name: 'nextCtaText',
			title: 'CTA for the continue in flow action',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			name: 'assistantConfig',
			type: 'reference',
			description: 'The assistant configuration to use for the home screen',
			to: [{ type: 'ItoAssistantConfig' }],
			validation: (r) => r.required(),
		},
	],
	preview: {
		select: {
			documentTitle: 'documentTitle',
		},
		prepare({ documentTitle }) {
			return {
				title: documentTitle,
			};
		},
	},
});
