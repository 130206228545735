import { HiOutlineDocumentText } from '@react-icons/all-files/hi/HiOutlineDocumentText';
import { defineType } from '@sanity/types';

import { Image } from '../_types';
import { previewNamedBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { FlowBasePageType } from './baseTypes';

export type FlowItoTherapistDetailsStepType = FlowBasePageType & {
	_type: 'FlowItoTherapistDetailsStep';
	backgroundImage: Image;
	headerImage: Image;
	headerImageLabel: string;
	title: string;
	content: BlockContentType;
};

export default defineType({
	name: 'FlowItoTherapistDetailsStep',
	type: 'document',
	icon: HiOutlineDocumentText,
	fields: [
		{
			name: 'backgroundImage',
			type: 'image',
			description: 'background image for the entire step content',
			validation: (r) => r.required(),
		},
		{
			name: 'headerImage',
			type: 'image',
			description: 'Header image for the step.',
			validation: (r) => r.required(),
		},
		{
			name: 'headerImageLabel',
			type: 'string',
			description: 'Support label for the header image',
		},
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
	],
	preview: previewNamedBlockContent('title'),
});
