import { useCallback, useMemo } from 'react';

import { FragmentType } from '../../content/FragmentType';
import { useFragment } from './useFragment';

export const useActivities = () => {
	const fragment = useFragment(FragmentType.Program);
	const activities = useMemo(
		() => fragment.items.flatMap((i) => i.items),
		[fragment],
	);

	const activityById = useCallback(
		(id: string) => activities.find((a) => a.id === id),
		[activities],
	);

	return {
		activities,
		activityById,
	};
};
