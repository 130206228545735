import { BrandColor } from '@wearemojo/ui-constants';
import React from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import Svg, {
	Circle,
	Defs,
	LinearGradient,
	RadialGradient,
	Stop,
} from 'react-native-svg';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import Text from './Text';

interface ChallengeProgressRingProps {
	size?: number;
	strokeWidth?: number;
	progress: number;
	total: number;
	shouldShowOuterBackdrop?: boolean;
}

const BOX_SIZE = 164;
const INNER_BOX_SIZE = 134;
const TEXT_BOX_SIZE = 80;

export default function ChallengeProgressRing({
	progress,
	total,
	shouldShowOuterBackdrop = true,
}: ChallengeProgressRingProps) {
	const { styles } = useStyles(stylesheet);
	const strokeWidth = 5;
	const center = INNER_BOX_SIZE / 2;
	const radius = INNER_BOX_SIZE / 2 - strokeWidth / 2;

	const { width: screenWidth, height: screenHeight } = useWindowDimensions();

	return (
		<View style={styles.outerContainer}>
			<Svg height={BOX_SIZE} width={BOX_SIZE} style={styles.svg}>
				<Defs>
					<RadialGradient id="grad" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
						<Stop offset="0%" stopColor="rgba(110, 110, 110, 0.3)" />
						<Stop offset="40%" stopColor="rgba(41, 41, 41, 0.3)" />
						<Stop offset="100%" stopColor="black" />
					</RadialGradient>
					<LinearGradient id="backdropGrad" x1="0%" y1="0%" x2="100%" y2="100%">
						<Stop offset="0%" stopColor="rgba(242, 246, 97, 0)" />
						<Stop offset="13%" stopColor="rgba(242, 246, 97, 0.03)" />
					</LinearGradient>
				</Defs>
				<Circle
					cx={BOX_SIZE / 2}
					cy={BOX_SIZE / 2}
					r={BOX_SIZE / 2}
					fill="url(#grad)"
				/>
			</Svg>
			<View style={styles.container}>
				<Svg height={INNER_BOX_SIZE} width={INNER_BOX_SIZE} style={styles.svg}>
					{/* Backdrop gradient circle */}
					{shouldShowOuterBackdrop ? (
						<Circle
							cx={center}
							cy={center}
							r={screenWidth / 2}
							stroke="url(#backdropGrad)"
							strokeWidth={screenHeight / 2}
							fill="none"
							strokeDasharray={`${2 * Math.PI * (screenWidth / 2)}`}
							strokeDashoffset={`${
								2 * Math.PI * (screenWidth / 2) * (1 - progress / total)
							}`}
						/>
					) : (
						<Circle
							cx={center}
							cy={center}
							r={radius}
							stroke="url(#backdropGrad)"
							strokeWidth={50}
							fill="none"
							strokeDasharray={`${2 * Math.PI * radius}`}
							strokeDashoffset={`${
								2 * Math.PI * radius * (1 - progress / total)
							}`}
						/>
					)}

					{/* Background circle */}
					<Circle
						cx={center}
						cy={center}
						r={radius}
						stroke={BrandColor.neutral_600}
						strokeWidth="0.24"
						fill="none"
					/>
					{/* Progress ring */}
					<Circle
						cx={center}
						cy={center}
						r={radius}
						stroke={BrandColor.primary_yellow}
						strokeWidth={strokeWidth}
						fill="none"
						strokeDasharray={`${2 * Math.PI * radius}`}
						strokeDashoffset={`${
							2 * Math.PI * radius * (1 - progress / total)
						}`}
					/>
				</Svg>
				<View style={styles.innerContainer}>
					<View style={styles.textContainer}>
						<Text
							variant="heading_md"
							themeColor="content_primary"
							style={styles.text}
						>
							{progress}
						</Text>
						<Text variant="heading_xxs" themeColor="content_neutral">
							/ {total}
						</Text>
					</View>
				</View>
			</View>
		</View>
	);
}

const stylesheet = createStyleSheet(({ colors, spacing }) => ({
	outerContainer: {
		height: BOX_SIZE,
		width: BOX_SIZE,
		borderRadius: BOX_SIZE / 2,
		alignItems: 'center',
		justifyContent: 'center',
		boxSizing: 'content-box',
		borderWidth: 1,
		borderColor: colors.content_neutral600,
	},
	container: {
		height: INNER_BOX_SIZE,
		width: INNER_BOX_SIZE,
		borderRadius: INNER_BOX_SIZE / 2,
		alignItems: 'center',
		justifyContent: 'center',
	},
	svg: {
		...StyleSheet.absoluteFillObject,
		transform: [{ rotate: '-90deg' }],
		overflow: 'visible',
	},
	innerContainer: {
		height: TEXT_BOX_SIZE,
		width: TEXT_BOX_SIZE,
		borderRadius: TEXT_BOX_SIZE / 2,
		backgroundColor: colors.content_neutral800,
		position: 'absolute',
		alignItems: 'center',
		justifyContent: 'center',
	},
	textContainer: {
		flexDirection: 'row',
		alignItems: 'baseline',
		justifyContent: 'center',
	},
	text: {
		marginRight: spacing.xs,
	},
}));
