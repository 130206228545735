import { Temporal } from '@js-temporal/polyfill';
import { Service, ServiceResponses } from '@wearemojo/api-client';
import {
	ExpressionArrayType,
	ExpressionBooleanType,
	ExpressionPlatformHasCapabilityType,
	ExpressionProgramActivityCompletedType,
	ExpressionProgramActivityNotCompletedType,
	ExpressionStringType,
	ProgramActivityFilterType,
	ProgramActivityType,
} from '@wearemojo/sanity-schema';
import { UIPlatform } from '@wearemojo/ui-constants';
import { getProperty } from 'dot-prop';
import { gte as semverGte } from 'semver';

import type { useEvaluateFeature } from '../hooks/useEvaluateFeature';
import { logger } from './logging';

type UserActivity =
	ServiceResponses[Service.learntracking]['listUserActivity'][number];

export type EvaluationArgs = {
	appPlatform: UIPlatform;
	appVersion: string;
	contextVariables?: Record<string, unknown>;
	currentTrackingDate?: Temporal.PlainDate;
	evaluateFeature: ReturnType<typeof useEvaluateFeature>['evaluateFeature'];
	pollAnswers?: Record<string, string[]>;
	programActivities?: Record<string, ProgramActivityType>;
	userActivity?: UserActivity[]; // currently `activity_completed` events only
};

export const evaluateBoolean = (
	expression: ExpressionBooleanType,
	args: EvaluationArgs,
): boolean => {
	switch (expression._type) {
		case 'ExpressionAppHasMinimumVersion':
			return semverGte(args.appVersion, expression.minimumVersion);

		case 'ExpressionArrayContainsString':
			const array = evaluateArray(expression.arrayOperand[0], args);
			const string = evaluateString(expression.stringOperand[0], args);
			return array.includes(string);

		case 'ExpressionArrayIsEmpty':
			return evaluateArray(expression.operand[0], args).length === 0;

		case 'ExpressionArrayIsNotEmpty':
			return evaluateArray(expression.operand[0], args).length > 0;

		case 'ExpressionConditionalBoolean':
			// must short-circuit to avoid evaluating unknown expression types
			const matchingCase = expression.cases.find((c) =>
				evaluateBoolean(c.conditionOperand[0], args),
			);
			return matchingCase
				? evaluateBoolean(matchingCase.valueOperand[0], args)
				: evaluateBoolean(expression.defaultValueOperand[0], args);

		case 'ExpressionConstantBoolean':
			return expression.value;

		case 'ExpressionFeatureIsEnabled':
			const featureKey = evaluateString(expression.featureKeyOperand[0], args);
			// a non-boolean feature that contains a value will be considered enabled
			return !!args.evaluateFeature(featureKey).isOn;

		case 'ExpressionLogicalAnd':
			return expression.operands.every((o) => evaluateBoolean(o, args));

		case 'ExpressionLogicalNot':
			return !evaluateBoolean(expression.operand[0], args);

		case 'ExpressionLogicalOr':
			return expression.operands.some((o) => evaluateBoolean(o, args));

		case 'ExpressionPlatformHasCapability':
			return evaluatePlatformHasCapability(expression.operand, args);

		case 'ExpressionProgramActivityCompleted':
			return evaluateProgramActivityCompleted(expression, args);

		case 'ExpressionProgramActivityNotCompleted':
			return evaluateProgramActivityNotCompleted(expression, args);

		case 'ExpressionReusableBoolean':
			return evaluateBoolean(expression.operand[0], args);

		case 'ExpressionStringIsEqual':
			return (
				evaluateString(expression.operands[0], args) ===
				evaluateString(expression.operands[1], args)
			);

		case 'ExpressionStringIsNotEqual':
			return (
				evaluateString(expression.operands[0], args) !==
				evaluateString(expression.operands[1], args)
			);

		default:
			((_: never) => {
				throw new Error(`Unknown ExpressionBooleanType ${JSON.stringify(_)}`);
			})(expression);
	}
};

const evaluateString = (
	expression: ExpressionStringType,
	args: EvaluationArgs,
): string => {
	switch (expression._type) {
		case 'ExpressionFeatureValueString':
			const featureKey = evaluateString(expression.featureKeyOperand[0], args);
			const featureValue = args.evaluateFeature(featureKey).value;
			return featureValue ? String(featureValue) : '';

		case 'ExpressionConditionalString':
			// must short-circuit to avoid evaluating unknown expression types
			const matchingCase = expression.cases.find((c) =>
				evaluateBoolean(c.conditionOperand[0], args),
			);
			return matchingCase
				? evaluateString(matchingCase.valueOperand[0], args)
				: evaluateString(expression.defaultValueOperand[0], args);

		case 'ExpressionConstantString':
			return expression.value;

		case 'ExpressionContextString':
			const contextPath = evaluateString(
				expression.contextPathOperand[0],
				args,
			);
			const value = getProperty(args.contextVariables, contextPath);
			return typeof value === 'string' ? value : '';

		case 'ExpressionReusableString':
			return evaluateString(expression.operand[0], args);

		default:
			((_: never) => {
				throw new Error(`Unknown ExpressionStringType ${JSON.stringify(_)}`);
			})(expression);
	}
};

const evaluateArray = (
	expression: ExpressionArrayType,
	args: EvaluationArgs,
): unknown[] => {
	switch (expression._type) {
		case 'ExpressionConditionalArray':
			// must short-circuit to avoid evaluating unknown expression types
			const matchingCase = expression.cases.find((c) =>
				evaluateBoolean(c.conditionOperand[0], args),
			);
			return matchingCase
				? evaluateArray(matchingCase.valueOperand[0], args)
				: evaluateArray(expression.defaultValueOperand[0], args);

		case 'ExpressionConstantArray':
			return expression.value;

		case 'ExpressionPollAnswerKeys':
			return args.pollAnswers?.[expression.poll.id] ?? [];

		case 'ExpressionReusableArray':
			return evaluateArray(expression.operand[0], args);

		default:
			((_: never) => {
				throw new Error(`Unknown ExpressionArrayType ${JSON.stringify(_)}`);
			})(expression);
	}
};

const evaluatePlatformHasCapability = (
	operand: ExpressionPlatformHasCapabilityType['operand'],
	args: EvaluationArgs,
): boolean => {
	switch (operand) {
		case 'notifications':
			return (
				args.appPlatform === UIPlatform.android ||
				args.appPlatform === UIPlatform.ios
			);
		default:
			((_: never) => {
				throw new Error(`Unknown ExpressionPlatformHasCapabilityType ${_}`);
			})(operand);
	}
};

const evaluateProgramActivityCompleted = (
	expression: ExpressionProgramActivityCompletedType,
	args: EvaluationArgs,
): boolean => {
	const { currentTrackingDate, programActivities, userActivity } = args;
	if (!currentTrackingDate || !programActivities || !userActivity) return false;

	const activity = userActivity.filter(
		(a) =>
			a.type === 'activity_completed' &&
			(expression.filters ?? []).every((f) =>
				evaluateProgramActivityFilter(a, f, {
					currentTrackingDate,
					programActivities,
				}),
			),
	);

	return activity.length >= expression.minCount;
};

const evaluateProgramActivityNotCompleted = (
	expression: ExpressionProgramActivityNotCompletedType,
	args: EvaluationArgs,
): boolean => {
	const { currentTrackingDate, programActivities, userActivity } = args;
	if (!currentTrackingDate || !programActivities || !userActivity) return false;

	const activity = userActivity.filter(
		(a) =>
			a.type === 'activity_completed' &&
			(expression.filters ?? []).every((f) =>
				evaluateProgramActivityFilter(a, f, {
					currentTrackingDate,
					programActivities,
				}),
			),
	);

	return activity.length < expression.minCount;
};

const evaluateProgramActivityFilter = (
	activityCompletion: Extract<UserActivity, { type: 'activity_completed' }>,
	filter: ProgramActivityFilterType,
	{
		currentTrackingDate,
		programActivities,
	}: Pick<
		Required<EvaluationArgs>,
		'currentTrackingDate' | 'programActivities'
	>,
) => {
	const completedActivityId = activityCompletion.params.activityId;

	switch (filter._type) {
		case 'ProgramActivityFilterExactActivity':
			return completedActivityId === filter.activity.id;

		case 'ProgramActivityFilterMaxAge':
			return (
				currentTrackingDate.since(activityCompletion.trackingDate).days <=
				filter.maxAgeDays
			);

		case 'ProgramActivityFilterSingleMetadata': {
			const activity = programActivities[completedActivityId];
			if (!activity) {
				logger.captureWarning('Activity not found', { completedActivityId });
				return false;
			}

			return activity.metadata?.some(
				(m) => m.key === filter.key && m.value === filter.value,
			);
		}

		default:
			((_: never) => {
				throw new Error(
					`Unknown ProgramActivityFilterType ${JSON.stringify(_)}`,
				);
			})(filter);
	}
};
