import { BrandColor } from '@wearemojo/ui-constants';
import {
	ComponentType,
	ElementRef,
	ReactNode,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import { ImageBackground, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BottomSheet from './BottomSheet';
import Button from './Button';
import Chip from './Chip';
import ContainedAction from './ContainedAction';
import Image from './Image';
import Stack from './Stack';
import Text from './Text';
import TextAction from './TextAction';
import {
	AbstractOverpanelSheetPattern,
	buttonPatternMapping,
	chipBackgroundPatternMapping,
	patternMapping,
} from './utils/abstractOverpanelSheet';
import {
	ButtonType,
	ContentVariables,
	InlineContent,
	MojoIcon,
} from './utils/types';

type Props = {
	visible?: boolean;
	onClose?: () => void;
	title: InlineContent;
	chipContent?: InlineContent;
	chipContentVariables?: ContentVariables;
	content?: InlineContent;
	contentNode?: ReactNode;
	ctaTitle?: string;
	onCtaPress?: () => void;
	isCtaLoading?: boolean;
	ctaIcon?: ComponentType<MojoIcon>;
	ctaIconPosition?: 'left' | 'right';
	secondaryCtaTitle?: string;
	onSecondaryCtaPress?: () => void;
	secondaryCtaIcon?: ComponentType<MojoIcon>;
	secondaryCtaIconPosition?: 'left' | 'right';
	textActionTitle?: string;
	textActionPress?: () => void;
	imageSource?: string;
	pattern?: AbstractOverpanelSheetPattern;
	backdropPressDisabled?: boolean;
	buttonType?: ButtonType;
};

const AbstractOverpanelSheet = ({
	visible = false,
	onClose,
	title,
	chipContent,
	chipContentVariables,
	imageSource,
	content,
	contentNode,
	ctaTitle,
	onCtaPress,
	isCtaLoading,
	ctaIcon,
	ctaIconPosition,
	secondaryCtaTitle,
	onSecondaryCtaPress,
	secondaryCtaIcon,
	secondaryCtaIconPosition,
	textActionTitle,
	textActionPress,
	pattern,
	backdropPressDisabled,
	buttonType,
}: Props) => {
	const {
		styles,
		theme: { spacing },
	} = useStyles(stylesheet, { pattern });
	const ref = useRef<ElementRef<typeof BottomSheet>>(null);

	useEffect(() => {
		if (visible) ref.current?.present();
		if (!visible) ref.current?.dismiss();
	}, [visible]);

	const handleOnClose = () => {
		ref.current?.toggleOverlay();
		onClose?.();
	};

	const chipBgColor = pattern
		? chipBackgroundPatternMapping[pattern]
		: 'neutral_300';

	const chipTextColor =
		!pattern || pattern === 'pattern_6'
			? 'content_on_dark'
			: 'content_on_light';

	return (
		<BottomSheet
			ref={ref}
			onDismiss={onClose}
			backdropPressDisabled={backdropPressDisabled}
		>
			<View style={styles.root}>
				<SheetContainer pattern={pattern}>
					<View style={styles.closeIconContainer}>
						{onClose && (
							<ContainedAction
								type="close"
								onPress={handleOnClose}
								position="end"
							/>
						)}
					</View>
					<Stack spacing={spacing.xl4} style={styles.contentContainer}>
						<Stack spacing={spacing.xl}>
							{chipContent && (
								<View style={styles.chipContainer}>
									<Chip
										title={chipContent}
										contentVariables={chipContentVariables}
										background={chipBgColor}
										textColor={chipTextColor}
									/>
								</View>
							)}
							<Text.CMSContent
								variant="heading_md_upper"
								value={title}
								style={styles.titleText}
							/>
							{content && (
								<Text.CMSContent
									variant="body_lg"
									value={content}
									style={styles.textContent}
								/>
							)}
							{contentNode}
						</Stack>
						<Stack spacing={spacing.sm}>
							{!!ctaTitle && (
								<View style={styles.buttonContainer}>
									<Button
										title={ctaTitle}
										onPress={onCtaPress}
										size="fixed"
										type={
											buttonType || (pattern && buttonPatternMapping[pattern])
										}
										icon={ctaIcon}
										iconPosition={ctaIconPosition}
										loading={isCtaLoading}
									/>
								</View>
							)}
							{!!secondaryCtaTitle && (
								<View style={styles.buttonContainer}>
									<Button
										title={secondaryCtaTitle}
										onPress={onSecondaryCtaPress}
										size="fixed"
										type="secondary"
										icon={secondaryCtaIcon}
										iconPosition={secondaryCtaIconPosition}
										loading={isCtaLoading}
									/>
								</View>
							)}
							{!!textActionTitle && (
								<View style={styles.buttonContainer}>
									<TextAction
										title={textActionTitle}
										onPress={textActionPress}
									/>
								</View>
							)}
						</Stack>
					</Stack>
				</SheetContainer>

				{imageSource && (
					<View style={styles.imageWrapper}>
						<Image
							source={{ uri: imageSource }}
							resizeMode="contain"
							style={styles.image}
						/>
					</View>
				)}
			</View>
		</BottomSheet>
	);
};

type SheetContainerProps = {
	pattern?: Props['pattern'];
	children?: ReactNode;
};
const SheetContainer = ({ pattern, ...props }: SheetContainerProps) => {
	const { styles, theme } = useStyles(stylesheet);
	const safeAreaInsets = useSafeAreaInsets();

	const containerStyle = useMemo(
		() =>
			StyleSheet.flatten([
				styles.container,
				{ paddingBottom: Math.max(safeAreaInsets.bottom, theme.spacing.xl) },
			]),
		[safeAreaInsets.bottom, styles.container, theme.spacing.xl],
	);

	if (!pattern) {
		return <View style={[containerStyle, styles.containerRadius]} {...props} />;
	}

	return (
		<ImageBackground
			source={patternMapping[pattern]()}
			resizeMode="cover"
			style={[styles.container, containerStyle]}
			imageStyle={styles.containerRadius}
			{...props}
		/>
	);
};

const stylesheet = createStyleSheet(({ spacing, radius, colors }) => ({
	root: {
		position: 'relative',
	},
	closeIconContainer: {
		height: 32,
	},
	imageWrapper: {
		position: 'absolute',
		top: -70,
		left: '50%',
		transform: [{ translateX: -62 }],
	},
	image: {
		height: 139,
		width: 125,
	},
	container: {
		paddingHorizontal: spacing.xl,
		paddingTop: spacing.xl,
	},
	containerRadius: {
		borderTopLeftRadius: radius.xl,
		borderTopRightRadius: radius.xl,
		backgroundColor: BrandColor.neutral_700,
	},
	contentContainer: {
		marginTop: 44,
	},
	chipContainer: {
		alignSelf: 'center',
	},
	titleText: {
		textAlign: 'center',

		variants: {
			pattern: {
				default: { color: colors.content_primary },
				pattern_1: { color: colors.content_on_light },
				pattern_2: { color: colors.content_on_light },
				pattern_3: { color: colors.content_on_light },
				pattern_4: { color: colors.content_on_light },
				pattern_5: { color: colors.content_on_light },
				pattern_6: { color: colors.content_on_dark },
				pattern_7: { color: colors.content_on_light },
			},
		},
	},
	textContent: {
		textAlign: 'center',

		variants: {
			pattern: {
				default: { color: colors.content_neutral100 },
				pattern_1: { color: colors.content_on_light },
				pattern_2: { color: colors.content_on_light },
				pattern_3: { color: colors.content_on_light },
				pattern_4: { color: colors.content_on_light },
				pattern_5: { color: colors.content_on_light },
				pattern_6: { color: colors.content_on_dark },
				pattern_7: { color: colors.content_on_light },
			},
		},
	},
	buttonContainer: {
		alignItems: 'center',
	},
}));

export default AbstractOverpanelSheet;
