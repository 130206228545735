import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';

import useEndpointQuery from '../../../hooks/queries/useEndpointQuery';
import { useLocalization } from '../../../hooks/useLocalization';
import { selectUserId } from '../../session';
import api from '..';

const useGetChallenges = () => {
	const userId = useSelector(selectUserId);
	const { timezone } = useLocalization();

	return useEndpointQuery(
		api.endpoints.getChallenges.useQuery(
			userId ? { timezone, userId } : skipToken,
		),
	);
};

export default useGetChallenges;
