import { Temporal } from '@js-temporal/polyfill';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';

import useEndpointQuery from '../../../hooks/queries/useEndpointQuery';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useLocalization } from '../../../hooks/useLocalization';
import { selectUserId } from '../../session';
import api from '..';
import useCurrentTrackingDate from './useCurrentTrackingDate';

export const useUserActivity = () => {
	const userId = useAppSelector(selectUserId);
	const { timezone } = useLocalization();
	const currentTrackingDate = useCurrentTrackingDate();

	const payload = userId && {
		userIdentifier: { type: 'user' as const, value: userId },
		timezone,
		filter: null,
		includeResetActivity: false,
		order: 'oldest_first' as const,
	};

	const { data: userActivity, isFetching: isFetchingUserActivity } =
		useEndpointQuery(
			api.endpoints.listUserActivity.useQuery(payload || skipToken),
		);

	const firstQueryableDate = useMemo(() => {
		if (!userActivity || isFetchingUserActivity) return;
		if (userActivity.length === 0) return currentTrackingDate;
		return Temporal.PlainDate.from(userActivity[0]!.trackingDate);
	}, [userActivity, isFetchingUserActivity, currentTrackingDate]);

	return {
		userActivity,
		firstQueryableDate,
		isFetchingUserActivity,
	};
};

export default useUserActivity;
