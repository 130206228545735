import { defineType } from '@sanity/types';

import { ActionType, InlineContentType } from '../..';
import { Document } from '../_types';
import {
	arrayOfTypesBoolean,
	ExpressionBooleanType,
} from '../expressions/_types';
import { renderExpression } from '../expressions/_utils';
import { Actions } from '../fields/actions/Actions';
import { ItoAssistantConfigType } from '../ItoAssistantConfig';

export enum ChatMenuIcon {
	calendar = 'calendar',
	cup = 'cup',
	refresh = 'refresh',
	database = 'database',
	report = 'report',
	sparkles = 'sparkles',
	lightning = 'lightning',
	notificationBell = 'notification-bell',
	questionMark = 'question-mark',
	headphones = 'headphones',
	fire = 'fire',
	pencil = 'pencil',
	speechBubble = 'speech-bubble',
	academicCap = 'academic-cap',
}

export const chatMenuIconsOrdered: ChatMenuIcon[] = [
	ChatMenuIcon.calendar,
	ChatMenuIcon.cup,
	ChatMenuIcon.refresh,
	ChatMenuIcon.database,
	ChatMenuIcon.report,
	ChatMenuIcon.sparkles,
	ChatMenuIcon.lightning,
	ChatMenuIcon.notificationBell,
	ChatMenuIcon.questionMark,
	ChatMenuIcon.headphones,
	ChatMenuIcon.fire,
	ChatMenuIcon.speechBubble,
	ChatMenuIcon.academicCap,
];

type ItoAssistantConfigCaseType = {
	condition: ExpressionBooleanType[];
	assistantConfig: ItoAssistantConfigType;
};

export type FragmentItoChatType = Document & {
	_type: 'FragmentItoChat';
	homeScreenConfigCases?: ItoAssistantConfigCaseType[];
	homeScreenConfigDefault: ItoAssistantConfigType;
	chatPlaceholder: string;
	disclaimerText: InlineContentType;
	memorySavedText: string;
	reportMessageTitle: string;
	defaultSupportText: string;
	chatActions: SendToChatOption[];
	actionOptions: ActionOption[];
	contactSupportCTA: string;
	findHelplineCTA: string;
	fallbackText: string;
};

type SendToChatOption = BaseChatMenuOption;

type ActionOption = BaseChatMenuOption & {
	action: [ActionType];
};

type BaseChatMenuOption = {
	text: string;
	icon: ChatMenuIcon;
};

export default defineType({
	name: 'FragmentItoChat',
	type: 'document',
	fields: [
		{
			name: 'homeScreenConfigCases',
			type: 'array',
			description:
				'To override the default home screen assistant config - the first matching case will be used',
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'condition',
							type: 'array',
							validation: (r) => r.required().min(1),
							of: arrayOfTypesBoolean,
							description: 'Condition to determine if this case should be used',
						},
						{
							name: 'assistantConfig',
							type: 'reference',
							description: 'Home screen assistant config if this case is used',
							validation: (r) => r.required(),
							to: [{ type: 'ItoAssistantConfig' }],
						},
					],
					preview: {
						select: {
							condition: 'condition',
						},
						prepare: ({ condition }: Partial<ItoAssistantConfigCaseType>) => ({
							subtitle: renderExpression({
								_type: 'ExpressionLogicalAnd',
								operands: condition ?? [],
							}),
						}),
					},
				},
			],
		},
		{
			name: 'homeScreenConfigDefault',
			type: 'reference',
			description: 'The default assistant config to use for the home screen',
			validation: (r) => r.required(),
			to: [{ type: 'ItoAssistantConfig' }],
		},
		{
			name: 'chatPlaceholder',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'disclaimerText',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'memorySavedText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'defaultSupportText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'reportMessageTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'contactSupportCTA',
			description:
				'The text of the button that triggers the contact support action',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'findHelplineCTA',
			description: 'The text of the button that redirects to findahelpline.com',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'fallbackText',
			type: 'string',
			description:
				'Message to show if OpenAI is down and we need a default home screen',
			validation: (r) => r.required(),
		},
		{
			name: 'chatActions',
			type: 'array',
			validation: (r) => r.required().min(1),
			of: [
				{
					type: 'object',
					fields: [
						{ name: 'text', type: 'string', validation: (r) => r.required() },
						{
							name: 'icon',
							type: 'string',
							options: {
								list: chatMenuIconsOrdered,
							},
							validation: (r) => r.required(),
						},
					],
				},
			],
		},
		{
			name: 'actionOptions',
			type: 'array',
			validation: (r) => r.required().min(1),
			of: [
				{
					type: 'object',
					fields: [
						{ name: 'text', type: 'string', validation: (r) => r.required() },
						{
							name: 'icon',
							type: 'string',
							options: {
								list: chatMenuIconsOrdered,
							},
							validation: (r) => r.required(),
						},
						{
							name: 'action',
							type: 'array',
							validation: (r) => r.max(1),
							of: Actions,
						},
					],
				},
			],
		},
	],
});
